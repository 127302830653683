import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from 'src/components/iconify/iconify';

import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { useCallback } from 'react';
import { format } from 'date-fns';

export default function TimePicker({ open, index, onClose, onFilters }) {
  const handleClose = () => {
    onClose();
  };

  const handleStartTime = useCallback((event) => {
    console.log("StartTime = ", event?.$d);
    try {
      var startTime = format(new Date(event?.$d), 'HH:mm');
      onFilters('startTime', startTime, index);
    } catch (error) {
      console.log(error)
    }

  });

  const handleEndTime = useCallback((event) => {
    try {
      var endTime = format(new Date(event?.$d), 'HH:mm');
      onFilters('endTime', endTime, index);
    } catch (error) {
      console.log(error)
    }
  });


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Iconify
        width="3rem"
        ml="auto"
        mr="auto"
        mt={2}
        sx={{ textAlign: 'center' }}
        icon="solar:sort-by-time-bold-duotone"
      />
      <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
        {'Set Business Hours'}
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ margin: '.5rem' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label="Open From">

              <MobileTimePicker onChange={handleStartTime} />
            </DemoItem>
          </LocalizationProvider>
        </div>
        <div style={{ margin: '.5rem' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label="Closed To">
              <MobileTimePicker onChange={handleEndTime} />
            </DemoItem>
          </LocalizationProvider>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ mt: 2 }}
          onClick={handleClose}
          variant="contained"
          color="warning"
          size="medium"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
