import { Helmet } from 'react-helmet-async';
// sections
import { ContactView } from 'src/sections/contact/view';
import ContactViewForm from 'src/sections/contact/view/contact-view-form';

// ----------------------------------------------------------------------

export default function ContactPageForm() {
  return (
    <>
      <Helmet>
        <title> Contact us</title>
      </Helmet>

      <ContactViewForm />
    </>
  );
}
