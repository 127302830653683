import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ContactForm from '../contact-form';
import ContactHero from '../contact-hero';

export default function ContactView() {
  return (
    <>
      {/* <ContactHero /> */}

      <ContactHero />
      <Container sx={{ marginTop: '1rem' }}>
        <Box>
          <ContactForm />
        </Box>
      </Container>
    </>
  );
}
