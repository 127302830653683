import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// components
import Iconify from 'src/components/iconify';
import { IconButton, Tooltip } from '@mui/material';
import UserSubCategoryQuickEditForm from './user-sub-quick-edit-form';
import { useDeleteCategoryMutation } from 'src/store/Reducer/category';
import { useSnackbar } from 'src/components/snackbar';
import Label from 'src/components/label';
// import { Label } from '@mui/icons-material';
// ----------------------------------------------------------------------

export default function DisplaySubcategories({ subcategories }) {
  const _mockFollowed = subcategories.slice(4, 8).map((i) => i.id);

  const [followed, setFollowed] = useState(_mockFollowed);

  const handleClick = useCallback(
    (item) => {
      const selected = followed.includes(item)
        ? followed.filter((value) => value !== item)
        : [...followed, item];

      setFollowed(selected);
    },
    [followed]
  );

  return (
    <>
      <Box
        sx={{ margin: '1rem' }}
        gap={1}
        display="flex"
        justifyContent={'center'}
        flexWrap={'wrap'}
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)',
          md: 'repeat(5, 1fr)',
        }}
      >
        {subcategories?.map((iter) => (
          <SubCategoryItem
            key={iter.id}
            subcategory={iter}
            selected={followed.includes(iter.id)}
            onSelected={() => handleClick(iter.id)}
          />
        ))}
      </Box>
    </>
  );
}

DisplaySubcategories.propTypes = {
  subcategories: PropTypes.array,
};

// ----------------------------------------------------------------------

function SubCategoryItem({ subcategory, selected, onSelected }) {
  const { name, image,isActive } = subcategory;
  console.log('subcategory = ', subcategory);
  
  const [quicksubEdit, setQuickSubEdit] = useState(false);
  const enqueueSnackbar = useSnackbar();
  const [deleteCategory] = useDeleteCategoryMutation();
  const handleDelete = async (data) => {
    try {
      const response = await deleteCategory({ id: subcategory?._id });

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        enqueueSnackbar('Deleted Successfully!');
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: (theme) => theme.spacing(3, 2, 3, 3),
        width: '20rem',
      }}
    >
      <Avatar alt={name} src={image} sx={{ width: 48, height: 48, mr: 2 }} />

      <Tooltip title={name} placement="top-start">
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            noWrap: true,
            typography: 'subtitle2',
          }}
        />
      </Tooltip>
      <Label variant="soft" color={(isActive && 'success') || 'error'}>
        {isActive ? 'Active' : 'Inactive'}
      </Label>
      <Box whiteSpace={'nowrap'}>
        <Tooltip title="Quick Edit" placement="top" arrow>
          <IconButton
            color={quicksubEdit ? 'inherit' : 'default'}
            onClick={() => setQuickSubEdit(true)}
          >
            <Iconify icon="solar:pen-bold" />
          </IconButton>
        </Tooltip>

        {/* <IconButton onClick={handleDelete}>
          <Iconify icon="solar:trash-bin-trash-bold" sx={{ color: 'error.main' }} />
        </IconButton> */}
      </Box>

      {quicksubEdit && (
        <UserSubCategoryQuickEditForm
          currentUser={subcategory}
          open={quicksubEdit}
          onClose={() => setQuickSubEdit(false)}
        />
      )}
    </Card>
  );
}

SubCategoryItem.propTypes = {
  subcategory: PropTypes.object,
  onSelected: PropTypes.func,
  selected: PropTypes.bool,
};
