import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { Card, FormControl, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFUploadAvatar,
  RHFRadioGroup,
} from 'src/components/hook-form';

import Switch from '@mui/material/Switch';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiColorInput } from 'mui-color-input';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormControlLabel } from '@mui/material';
import dayjs from 'dayjs';
import TeamMemberTimingSlots from './team-members-timing-slots';
import { useAddNewMembersMutation } from 'src/store/Reducer/members';
import { useUploadNewFileMutation } from 'src/store/Reducer/file';
import { useParams } from 'react-router-dom';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
// ----------------------------------------------------------------------

export default function UserNewEditForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const { branch } = useMockedBranch();
  const [addNewMembers] = useAddNewMembersMutation();

  const [uploadFile] = useUploadNewFileMutation();
  const NewUserSchema = Yup.object().shape({
    image: Yup.mixed().nullable().required('Avatar is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    mobile: Yup.string().required('Phone number is required'),
    availableForHomeService: Yup.boolean().required('HomeService is required'),
    jobTitle: Yup.string().required('Job Title is required'),
    password: Yup.string().required('Password is required'),
    gender: Yup.string().required('Gender is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();


  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await uploadFile(data.image);

      const filename = result.data?.body?.fileName;

      const newData = {
        image: filename,
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        availableForHomeService: data.availableForHomeService,
        jobTitle: data.jobTitle,
        gender: data.gender,
        branch: branch?._id,
        password: data.password,
      };

      const response = await addNewMembers(newData);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Member Created Successfully!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Create Team Member</DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 5 }}>
            <RHFUploadAvatar
              name="image"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                ></Typography>
              }
            />
          </Box>
          <Box
            marginTop={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="name" label="Name" />
            <RHFTextField name="email" label="Email" />
            <RHFTextField name="mobile" label="Contact Number" />
            <RHFTextField name="jobTitle" label="Job Title" />
            <RHFTextField name="password" label="Password" />

            <RHFSelect name="availableForHomeService" label="Home Service Availability">
              <MenuItem value={true}>Available</MenuItem>
              <MenuItem value={false}>Notavailable</MenuItem>
            </RHFSelect>
            <RHFRadioGroup
              row
              name="gender"
              label={'Gender'}
              options={[
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
              ]}
            />
          </Box>
        </DialogContent>
        {branch?.subscription?.plan?.type === 'advanced' && (
          <Box sx={{padding:"10px"}} >
            <b>Note:</b> A charge of {branch?.subscription?.plan?.additionalPrice} {' '} {branch?.subscription?.plan?.currency}  will apply
            for each additional user in your plan.
          </Box>
        )}

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserNewEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
