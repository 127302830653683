import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';

import Iconify from 'src/components/iconify';
import { useState } from 'react';
import FaqsHero from '../faq/faqs-hero';
import axios from 'axios';
import { useMockedUser } from 'src/hooks/use-mocked-user';
export default function TestView() {
  const { user } = useMockedUser();
  const initialValues = [
    { title: 'Login & Registraion', url: 'auth/test/apis', result: [], },
    { title: 'Categories', url: 'categories/test/apis', result: [] },
    { title: 'Size Type & Values', url: 'size/test/apis', result: [] },
    { title: 'Gift Cards', url: 'gift-cards/test/apis', result: [] },
    { title: 'Sponsorships & Pre Signups', url: 'sponsorships/test/apis', result: [] },
    { title: 'Business', url: 'business/test/apis', result: [] },
  ];
  const [modules, setmodules] = useState(initialValues);
  const [loading, setloading] = useState(false);
  const fetchData = async ({ index, url }) => {
    try {
      if (modules[index]?.result?.length > 0) return;
      setloading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${user?.accesstoken}`,
          'Content-Type': 'application/json',
        },
      };
      const res = await axios.get(`${process.env.REACT_APP_HOST_API + url}`, config);
      setmodules((prev) => {
        let arr = prev;
        arr[index].result = res.data;
        return arr;
      });
      setloading(false);
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };
const methodsClr={post:'info',put:'warning',get:'success',delete:'error'}
  return (
    <>
      <FaqsHero data={'Status'} />
      <Container
        sx={{
          pb: 2,
          pt: { xs: 10, md: 1 },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              my: { xs: 5, md: 1 },
            }}
          >
            Timezzi Testing Modules
          </Typography>
        </Box>

        <Box gap={1}>
          <div>
            {modules?.map((accordion, index) => (
              <Accordion key={index} sx={{ width: '100%' }}>
                <AccordionSummary
                   onClick={() => fetchData({index,url:accordion.url})}
                  expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
                >
                  <Typography variant="subtitle1">{accordion.title}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Box>
                    {loading && !accordion.result.length>0 ? (
                      <Box sx={{ textAlign: 'center' }}>
                        <CircularProgress />
                        <Typography>Loading Results, Please wait...</Typography>
                      </Box>
                    ) : (
                      <List>
                        {accordion?.result?.map((status, index) => (
                          <ListItem key={index}>
                            <ListItemIcon>
                              <Iconify
                                icon={
                                  status.status === 'fulfilled'
                                    ? 'mdi:check-circle'
                                    : 'mdi:close-circle'
                                }
                                width={24}
                                height={24}
                                color={status.status === 'fulfilled' ? 'green' : 'red'}
                                style={{ cursor: 'pointer' }}
                              />
                            </ListItemIcon>
                            <ListItemText>
                              <Typography variant="body1">
                                <Stack spacing={2} direction={'row'}>
                                <Button sx={{width:"150px"}} variant='soft' color={methodsClr[status.method]} >{status.label}</Button> 
                                <span>EndPoint:{status.endpoint} </span>
                                {status.status==='rejected'&&
                                  <span>Reason:&nbsp; {status.result?.error ||'something went wrong!'} </span>
                                }
                                </Stack>
                              </Typography>
                            </ListItemText>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Box>
      </Container>
    </>
  );
}
