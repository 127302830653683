import React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { LoadingButton } from '@mui/lab';
import { useLocales } from 'src/locales';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import { borderRadius, height } from '@mui/system';
import { useSendContactQueryMutation } from 'src/store/Reducer/settings';
// import { useAddNewSposnorShipsMutation } from 'src/store/Reducer/landingpage';

export default function ContactUsForm() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // const [addNewSposnorShips] = useAddNewSposnorShipsMutation()

  const [sendContactQuery] = useSendContactQueryMutation();

  const integerValidation = (value) => {
    if (!Number.isInteger(value)) {
      return false;
    }
    return true;
  };

  const NewUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.number().test(
      'PhoneNumber',
      'Please enter an integer value',
      integerValidation
    ),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log(values);

  // HANDLE SUBMIT
  const onSubmit = handleSubmit(async (data) => {
    try {
      const newData = {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
        subject: data.subject,
        message: data.message,
      };

      console.log('newData = ', newData);

      let response = await sendContactQuery(newData);
      console.log('Response = ', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        enqueueSnackbar('Success!');
        reset({ name: '', email: '', phoneNumber: '', subject: '', message: '' });
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Grid
      component={MotionViewport}
      container
      spacing={5}
      justifyContent="center"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        margin: '0rem 0rem',
      }}
    >
      <Grid
        marginTop={'2rem'}
        item
        xs={12}
        md={12}
        sx={{
          borderRadius: '2rem',
          backgroundColor: '#F5EFFD',
          border: '1px solid #7635DC',
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogContent sx={{ padding: '2rem 2rem' }}>
            <Box
              marginTop="1rem"
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="name"
                label="Name"
                sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
              />
              <RHFTextField
                name="email"
                label="Email"
                sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
              />
              <RHFTextField
                name="phoneNumber"
                type="number"
                label="Phone Number"
                sx={{ backgroundColor: '#fff', borderRadius: '10px', height: '3.3rem' }}
              />
              <RHFTextField
                name="subject"
                label="Subject"
                sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
              />
            </Box>

            <Box
              marginTop="1rem"
              rowGap={1}
              columnGap={1}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
              }}
            >
              <RHFTextField
                name="message"
                label="message"
                multiline
                rows={4}
                sx={{ backgroundColor: '#fff', borderRadius: '10px' }}
              />
            </Box>

            <LoadingButton
              color="primary"
              size="large"
              sx={{ minWidth: '10rem', marginTop: '1rem' }}
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </DialogContent>
        </FormProvider>
      </Grid>
    </Grid>
  );
}
