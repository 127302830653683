import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import BusinessVacations from 'src/sections/business/BusinessHours/business-vacations';
import ProfileHome from 'src/sections/business/profile-home';
import StatusPage from 'src/pages/test';
// import NewCustomerListPage from 'src/pages/dashboard/newCustomers/NewCustomerListPage';
// import UserDetailPage from 'src/pages/dashboard/user/profile';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));

// Booking
const BookingProfilePage = lazy(() => import('src/pages/dashboard/booking/profile'));
const BookingCardsPage = lazy(() => import('src/pages/dashboard/booking/cards'));
const BookingListPage = lazy(() => import('src/pages/dashboard/booking/list'));
const BookingAccountPage = lazy(() => import('src/pages/dashboard/booking/account'));
const BookingCreatePage = lazy(() => import('src/pages/dashboard/booking/new'));
const BookingEditPage = lazy(() => import('src/pages/dashboard/booking/edit'));

// SignUp
const SignUpProfilePage = lazy(() => import('src/pages/dashboard/signup/profile'));
const SignUpListPage = lazy(() => import('src/pages/dashboard/signup/list'));

// Sponsors
const SponsorsListPage = lazy(() => import('src/pages/dashboard/sponsors/list'));

// TypeAndValue
const TypeAndValueProfilePage = lazy(() => import('src/pages/dashboard/typeAndValues/profile'));
const TypeAndValueListPage = lazy(() => import('src/pages/dashboard/typeAndValues/list'));

// Booking
const DiscountsProfilePage = lazy(() => import('src/pages/dashboard/discounts/profile'));
const DiscountsCardsPage = lazy(() => import('src/pages/dashboard/discounts/cards'));
const DiscountsListPage = lazy(() => import('src/pages/dashboard/discounts/list'));
const DiscountsAccountPage = lazy(() => import('src/pages/dashboard/discounts/account'));
const DiscountsCreatePage = lazy(() => import('src/pages/dashboard/discounts/new'));
const DiscountsEditPage = lazy(() => import('src/pages/dashboard/discounts/edit'));

// GiftCards
const GiftCardsListPage = lazy(() => import('src/pages/dashboard/giftcards/list'));

// Admin GiftCards
const AdminGiftCardsListPage = lazy(() => import('src/pages/dashboard/admin-giftcards/list'));

// Members
const MembersProfilePage = lazy(() => import('src/pages/dashboard/members/profile'));
const MembersCardsPage = lazy(() => import('src/pages/dashboard/members/cards'));
const MembersListPage = lazy(() => import('src/pages/dashboard/members/list'));
const MembersAccountPage = lazy(() => import('src/pages/dashboard/members/account'));
const MembersCreatePage = lazy(() => import('src/pages/dashboard/members/new'));
const MembersEditPage = lazy(() => import('src/pages/dashboard/members/edit'));

// Memberships
const MembershipsProfilePage = lazy(() => import('src/pages/dashboard/memberships/profile'));
const MembershipsCardsPage = lazy(() => import('src/pages/dashboard/memberships/cards'));
const MembershipsListPage = lazy(() => import('src/pages/dashboard/memberships/list'));
const MembershipsAccountPage = lazy(() => import('src/pages/dashboard/memberships/account'));
const MembershipsCreatePage = lazy(() => import('src/pages/dashboard/memberships/new'));
const MembershipsEditPage = lazy(() => import('src/pages/dashboard/memberships/edit'));

// Agents
const PartnersProfilePage = lazy(() => import('src/pages/dashboard/partners/profile'));
const PartnersCardsPage = lazy(() => import('src/pages/dashboard/partners/cards'));
const PartnersListPage = lazy(() => import('src/pages/dashboard/partners/list'));
const PartnersAccountPage = lazy(() => import('src/pages/dashboard/partners/account'));
const PartnersCreatePage = lazy(() => import('src/pages/dashboard/partners/new'));
const PartnersEditPage = lazy(() => import('src/pages/dashboard/partners/edit'));

// Services
const ServicesProfilePage = lazy(() => import('src/pages/dashboard/services/profile'));
const ServicesCardsPage = lazy(() => import('src/pages/dashboard/services/cards'));
const ServicesListPage = lazy(() => import('src/pages/dashboard/services/list'));
const ServicesAccountPage = lazy(() => import('src/pages/dashboard/services/account'));
const ServicesCreatePage = lazy(() => import('src/pages/dashboard/services/new'));
const ServicesEditPage = lazy(() => import('src/pages/dashboard/services/edit'));

// Calendar2Way
const Calendar2wayProfilePage = lazy(() => import('src/pages/dashboard/calendar2waysync/profile'));

// Wallet
const WalletProfilePage = lazy(() => import('src/pages/dashboard/wallet/profile'));

// Admin-Compaigns
const AdminCompaignsProfilePage = lazy(() => import('src/pages/dashboard/admin-compaigns/profile'));

// Category
const CategoryProfilePage = lazy(() => import('src/pages/dashboard/category/profile'));
const CategoryCardsPage = lazy(() => import('src/pages/dashboard/category/cards'));
const CategoryListPage = lazy(() => import('src/pages/dashboard/category/list'));
const CategoryAccountPage = lazy(() => import('src/pages/dashboard/category/account'));
const CategoryCreatePage = lazy(() => import('src/pages/dashboard/category/new'));
const CategoryEditPage = lazy(() => import('src/pages/dashboard/category/edit'));

// Customer
const CustomerProfilePage = lazy(() => import('src/pages/dashboard/customer/profile'));
const CustomerCardsPage = lazy(() => import('src/pages/dashboard/customer/cards'));
const CustomerListPage = lazy(() => import('src/pages/dashboard/customer/list'));
const CustomerAccountPage = lazy(() => import('src/pages/dashboard/customer/account'));
const CustomerCreatePage = lazy(() => import('src/pages/dashboard/customer/new'));
const CustomerEditPage = lazy(() => import('src/pages/dashboard/customer/edit'));
// Business
const BusinessProfilePage = lazy(() => import('src/pages/dashboard/business/profile'));
const BusinessCardsPage = lazy(() => import('src/pages/dashboard/business/cards'));
const BusinessListPage = lazy(() => import('src/pages/dashboard/business/list'));
const BusinessAccountPage = lazy(() => import('src/pages/dashboard/business/account'));
const BusinessCreatePage = lazy(() => import('src/pages/dashboard/business/new'));
const BusinessEditPage = lazy(() => import('src/pages/dashboard/business/edit'));

// Products
const ProductsProfilePage = lazy(() => import('src/pages/dashboard/products/profile'));
const ProductsCardsPage = lazy(() => import('src/pages/dashboard/products/cards'));
const ProductsListPage = lazy(() => import('src/pages/dashboard/products/list'));
const ProductsAccountPage = lazy(() => import('src/pages/dashboard/products/account'));
const ProductsCreatePage = lazy(() => import('src/pages/dashboard/products/new'));
const ProductsEditPage = lazy(() => import('src/pages/dashboard/products/edit'));

// Transfer
const TransferProfilePage = lazy(() => import('src/pages/dashboard/transfer/profile'));
const TransferCardsPage = lazy(() => import('src/pages/dashboard/transfer/cards'));
const TransferListPage = lazy(() => import('src/pages/dashboard/transfer/list'));
const TransferAccountPage = lazy(() => import('src/pages/dashboard/transfer/account'));
const TransferCreatePage = lazy(() => import('src/pages/dashboard/transfer/new'));
const TransferEditPage = lazy(() => import('src/pages/dashboard/transfer/edit'));

// Equipments
const EquipmentsProfilePage = lazy(() => import('src/pages/dashboard/equipments/profile'));
const EquipmentsCardsPage = lazy(() => import('src/pages/dashboard/equipments/cards'));
const EquipmentsListPage = lazy(() => import('src/pages/dashboard/equipments/list'));
const EquipmentsAccountPage = lazy(() => import('src/pages/dashboard/equipments/account'));
const EquipmentsCreatePage = lazy(() => import('src/pages/dashboard/equipments/new'));
const EquipmentsEditPage = lazy(() => import('src/pages/dashboard/equipments/edit'));

// Subscription
const SubscriptionProfilePage = lazy(() => import('src/pages/dashboard/subscription/profile'));
const SubscriptionCardsPage = lazy(() => import('src/pages/dashboard/subscription/cards'));
const SubscriptionListPage = lazy(() => import('src/pages/dashboard/subscription/list'));
const SubscriptionAccountPage = lazy(() => import('src/pages/dashboard/subscription/account'));
const SubscriptionCreatePage = lazy(() => import('src/pages/dashboard/subscription/new'));
const SubscriptionEditPage = lazy(() => import('src/pages/dashboard/subscription/edit'));

// Subscription-Plans
const SubscriptionPlansProfilePage = lazy(() =>
  import('src/pages/dashboard/subscription-plans/profile')
);
const SubscriptionPlansCardsPage = lazy(() =>
  import('src/pages/dashboard/subscription-plans/cards')
);
const SubscriptionPlansListPage = lazy(() => import('src/pages/dashboard/subscription-plans/list'));
const SubscriptionPlansAccountPage = lazy(() =>
  import('src/pages/dashboard/subscription-plans/account')
);
const SubscriptionPlansCreatePage = lazy(() =>
  import('src/pages/dashboard/subscription-plans/new')
);
const SubscriptionPlansEditPage = lazy(() => import('src/pages/dashboard/subscription-plans/edit'));

// PAYMENT Payment
const PaymentListPage = lazy(() => import('src/pages/dashboard/payment/list'));
const PaymentDetailsPage = lazy(() => import('src/pages/dashboard/payment/details'));
const PaymentCreatePage = lazy(() => import('src/pages/dashboard/payment/new'));
const PaymentEditPage = lazy(() => import('src/pages/dashboard/payment/edit'));

// OFFERS
const OffersProfilePage = lazy(() => import('src/pages/dashboard/offers/profile'));
const OffersCardsPage = lazy(() => import('src/pages/dashboard/offers/cards'));
const OffersListPage = lazy(() => import('src/pages/dashboard/offers/list'));
const OffersAccountPage = lazy(() => import('src/pages/dashboard/offers/account'));
const OffersCreatePage = lazy(() => import('src/pages/dashboard/offers/new'));
const OffersEditPage = lazy(() => import('src/pages/dashboard/offers/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));

// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
const FaqsPage = lazy(() => import('src/pages/dashboard/faqs'));
const AdminFaqsPage = lazy(() => import('src/pages/dashboard/AdminFaqs'));
const PrivacyPage = lazy(() => import('src/pages/dashboard/privacy'));
const TermsPage = lazy(() => import('src/pages/dashboard/terms'));
const AboutUsPage = lazy(() => import('src/pages/dashboard/aboutUs'));
const ContactUsPage = lazy(() => import('src/pages/contact-us'));
const QrCodePage = lazy(() => import('src/sections/business/qr-code-view'));

// Campaigns
const CampaignsListPage = lazy(() => import('src/pages/dashboard/campaigns/list'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'admin/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'Faqs', element: <AdminFaqsPage /> },
      { path: 'status', element: <StatusPage /> },
      { path: 'terms/customer', element: <TermsPage /> },
      { path: 'terms/business', element: <TermsPage /> },
      { path: 'privacy/customer', element: <PrivacyPage /> },
      { path: 'privacy/business', element: <PrivacyPage /> },
      { path: 'aboutUs/customer', element: <AboutUsPage /> },
      { path: 'aboutUs/business', element: <AboutUsPage /> },
      { path: 'contactUs', element: <ContactUsPage /> },
      { path: 'calendar2way', element: <Calendar2wayProfilePage /> },
      // { path: 'giftcards', element: <GiftCardsListPage /> },
      { path: 'gift-cards', element: <AdminGiftCardsListPage /> },
      { path: 'wallet', element: <WalletProfilePage /> },
      // { path: 'campaigns', element: <CompaignsProfilePage /> },

      // { path: 'customers', element: <CustomerListPage /> },

      {
        path: 'business',
        children: [
          { element: <BusinessProfilePage />, index: true },
          {
            path: ':businessID',
            element: (
              <BusinessProfilePage>
                <Suspense fallback={<LoadingScreen />}>
                  <Outlet />
                </Suspense>
              </BusinessProfilePage>
            ),
            children: [
              {
                path: 'booking',
                children: [
                  { element: <BookingListPage />, index: true },
                  { path: ':bookingID', element: <BookingProfilePage /> },
                  { path: 'cards', element: <BookingCardsPage /> },
                  { path: 'list', element: <BookingListPage /> },
                  { path: 'new', element: <BookingCreatePage /> },
                  { path: ':id/edit', element: <BookingEditPage /> },
                  { path: 'account', element: <BookingAccountPage /> },
                ],
              },
              {
                path: 'services',
                children: [
                  { element: <ServicesListPage />, index: true },
                  { path: 'profile', element: <ServicesProfilePage /> },
                  { path: 'cards', element: <ServicesCardsPage /> },
                  { path: 'list', element: <ServicesListPage /> },
                  { path: 'new', element: <ServicesCreatePage /> },
                  { path: ':id/edit', element: <ServicesEditPage /> },
                  { path: 'account', element: <ServicesAccountPage /> },
                ],
              },
              {
                path: 'members',
                children: [
                  { element: <MembersListPage />, index: true },
                  { path: 'profile', element: <MembersProfilePage /> },
                  { path: 'cards', element: <MembersCardsPage /> },
                  { path: 'list', element: <MembersListPage /> },
                  { path: 'new', element: <MembersCreatePage /> },
                  { path: ':id/edit', element: <MembersEditPage /> },
                  { path: 'account', element: <MembersAccountPage /> },
                ],
              },
              {
                path: 'memberships',
                children: [
                  { element: <MembershipsListPage />, index: true },
                  { path: ':membershipID', element: <MembershipsProfilePage /> },
                  { path: 'cards', element: <MembershipsCardsPage /> },
                  { path: 'list', element: <MembershipsListPage /> },
                  { path: 'new', element: <MembershipsCreatePage /> },
                  { path: ':id/edit', element: <MembershipsEditPage /> },
                  { path: 'account', element: <MembershipsAccountPage /> },
                ],
              },
              {
                path: 'vacations',
                children: [{ element: <BusinessVacations />, index: true }],
              },
              {
                path: 'profile',
                children: [{ element: <ProfileHome />, index: true }],
              },
              {
                path: 'giftcards',
                children: [{ element: <GiftCardsListPage />, index: true }],
              },

              {
                path: 'campaigns',
                children: [{ element: <CampaignsListPage />, index: true }],
              },
              {
                path: 'QRCode',
                children: [{ element: <QrCodePage />, index: true }],
              },
              {
                path: 'payment',
                children: [
                  { element: <PaymentListPage />, index: true },
                  { path: 'list', element: <PaymentListPage /> },
                  { path: ':id', element: <PaymentDetailsPage /> },
                  { path: ':id/edit', element: <PaymentEditPage /> },
                  { path: 'new', element: <PaymentCreatePage /> },
                ],
              },
              {
                path: 'products',
                children: [
                  { element: <ProductsListPage />, index: true },
                  { path: ':productsID', element: <ProductsProfilePage /> },
                  { path: 'cards', element: <ProductsCardsPage /> },
                  { path: 'list', element: <ProductsListPage /> },
                  { path: 'new', element: <ProductsCreatePage /> },
                  { path: ':id/edit', element: <ProductsEditPage /> },
                  { path: 'account', element: <ProductsAccountPage /> },
                ],
              },
              {
                path: 'offers',
                children: [
                  { element: <OffersListPage />, index: true },
                  { path: 'profile', element: <OffersProfilePage /> },
                  { path: 'cards', element: <OffersCardsPage /> },
                  { path: 'list', element: <OffersListPage /> },
                  { path: 'new', element: <OffersCreatePage /> },
                  { path: ':id/edit', element: <OffersEditPage /> },
                  { path: 'account', element: <OffersAccountPage /> },
                ],
              },
              {
                path: 'discounts',
                children: [
                  { element: <DiscountsListPage />, index: true },
                  { path: 'profile', element: <DiscountsProfilePage /> },
                  { path: 'cards', element: <DiscountsCardsPage /> },
                  { path: 'list', element: <DiscountsListPage /> },
                  { path: 'new', element: <DiscountsCreatePage /> },
                  { path: ':id/edit', element: <DiscountsEditPage /> },
                  { path: 'account', element: <DiscountsAccountPage /> },
                ],
              },
            ],
          },
          { path: 'cards', element: <BusinessCardsPage /> },
          { path: 'list', element: <BusinessListPage /> },
          { path: 'new', element: <BusinessCreatePage /> },
          { path: ':id/edit', element: <BusinessEditPage /> },
          { path: 'account', element: <BusinessAccountPage /> },
        ],
      },
      // {
      //   path: 'User',
      //   children: [{ element: <UserDetailPage />, index: true }],
      // },
      {
        path: 'booking',
        children: [
          { element: <BookingProfilePage />, index: true },
          { path: 'profile', element: <BookingProfilePage /> },
          { path: 'cards', element: <BookingCardsPage /> },
          { path: 'list', element: <BookingListPage /> },
          { path: 'new', element: <BookingCreatePage /> },
          { path: ':id/edit', element: <BookingEditPage /> },
          { path: 'account', element: <BookingAccountPage /> },
        ],
      },
      {
        path: 'signup',
        children: [
          { element: <SignUpListPage />, index: true },
          { path: 'profile', element: <SignUpProfilePage /> },
          { path: 'list', element: <SignUpListPage /> },
        ],
      },
      {
        path: 'sponsors',
        children: [
          { element: <SponsorsListPage />, index: true },
          { path: 'list', element: <SponsorsListPage /> },
        ],
      },
      {
        path: 'type&values',
        children: [
          { element: <TypeAndValueListPage />, index: true },
          { path: 'profile', element: <TypeAndValueProfilePage /> },
          { path: 'list', element: <TypeAndValueListPage /> },
        ],
      },
      {
        path: 'campaigns',
        children: [{ element: <AdminCompaignsProfilePage />, index: true }],
      },
      {
        path: 'discounts',
        children: [
          { element: <DiscountsListPage />, index: true },
          { path: 'profile', element: <DiscountsProfilePage /> },
          { path: 'cards', element: <DiscountsCardsPage /> },
          { path: 'list', element: <DiscountsListPage /> },
          { path: 'new', element: <DiscountsCreatePage /> },
          { path: ':id/edit', element: <DiscountsEditPage /> },
          { path: 'account', element: <DiscountsAccountPage /> },
        ],
      },
      {
        path: 'subscription-plans',
        children: [
          { element: <SubscriptionPlansListPage />, index: true },
          { path: 'profile', element: <SubscriptionPlansProfilePage /> },
          { path: 'cards', element: <SubscriptionPlansCardsPage /> },
          { path: 'list', element: <SubscriptionPlansListPage /> },
          { path: 'new', element: <SubscriptionPlansCreatePage /> },
          { path: ':id/edit', element: <SubscriptionPlansEditPage /> },
          { path: 'account', element: <SubscriptionPlansAccountPage /> },
        ],
      },
      {
        path: 'members',
        children: [
          { element: <MembersProfilePage />, index: true },
          { path: 'profile', element: <MembersProfilePage /> },
          { path: 'cards', element: <MembersCardsPage /> },
          { path: 'list', element: <MembersListPage /> },
          { path: 'new', element: <MembersCreatePage /> },
          { path: ':id/edit', element: <MembersEditPage /> },
          { path: 'account', element: <MembersAccountPage /> },
        ],
      },
      {
        path: 'partners',
        children: [
          { element: <PartnersProfilePage />, index: true },
          { path: 'profile', element: <PartnersProfilePage /> },
          { path: 'cards', element: <PartnersCardsPage /> },
          { path: 'list', element: <PartnersListPage /> },
          { path: 'new', element: <PartnersCreatePage /> },
          { path: ':id/edit', element: <PartnersEditPage /> },
          { path: 'account', element: <PartnersAccountPage /> },
        ],
      },
      {
        path: 'services',
        children: [
          { element: <ServicesProfilePage />, index: true },
          { path: 'profile', element: <ServicesProfilePage /> },
          { path: 'cards', element: <ServicesCardsPage /> },
          { path: 'list', element: <ServicesListPage /> },
          { path: 'new', element: <ServicesCreatePage /> },
          { path: ':id/edit', element: <ServicesEditPage /> },
          { path: 'account', element: <ServicesAccountPage /> },
        ],
      },
      {
        path: 'category',
        children: [
          { element: <CategoryProfilePage />, index: true },
          { path: 'profile', element: <CategoryProfilePage /> },
          { path: 'cards', element: <CategoryCardsPage /> },
          { path: 'list', element: <CategoryListPage /> },
          { path: 'new', element: <CategoryCreatePage /> },
          { path: ':id/edit', element: <CategoryEditPage /> },
          { path: 'account', element: <CategoryAccountPage /> },
        ],
      },
      {
        path: 'customers',
        children: [
          { element: <CustomerListPage />, index: true },
          { path: 'profile/:id', element: <CustomerProfilePage /> },
          { path: 'cards', element: <CustomerCardsPage /> },
          { path: 'list', element: <CustomerListPage /> },
          { path: 'new', element: <CustomerCreatePage /> },
          { path: ':id/edit', element: <CustomerEditPage /> },
          { path: 'account', element: <CustomerAccountPage /> },
        ],
      },
      {
        path: 'transfer',
        children: [
          { element: <TransferProfilePage />, index: true },
          { path: 'profile', element: <TransferProfilePage /> },
          { path: 'cards', element: <TransferCardsPage /> },
          { path: 'list', element: <TransferListPage /> },
          { path: 'new', element: <TransferCreatePage /> },
          { path: ':id/edit', element: <TransferEditPage /> },
          { path: 'account', element: <TransferAccountPage /> },
        ],
      },
      {
        path: 'equipments',
        children: [
          { element: <EquipmentsProfilePage />, index: true },
          { path: 'profile', element: <EquipmentsProfilePage /> },
          { path: 'cards', element: <EquipmentsCardsPage /> },
          { path: 'list', element: <EquipmentsListPage /> },
          { path: 'new', element: <EquipmentsCreatePage /> },
          { path: ':id/edit', element: <EquipmentsEditPage /> },
          { path: 'account', element: <EquipmentsAccountPage /> },
        ],
      },
      {
        path: 'subscription',
        children: [
          { element: <SubscriptionProfilePage />, index: true },
          { path: 'profile', element: <SubscriptionProfilePage /> },
          { path: 'cards', element: <SubscriptionCardsPage /> },
          { path: 'list', element: <SubscriptionListPage /> },
          { path: 'new', element: <SubscriptionCreatePage /> },
          { path: ':id/edit', element: <SubscriptionEditPage /> },
          { path: 'account', element: <SubscriptionAccountPage /> },
        ],
      },
      {
        path: 'payment',
        children: [
          { element: <PaymentListPage />, index: true },
          { path: 'list', element: <PaymentListPage /> },
          { path: ':id', element: <PaymentDetailsPage /> },
          { path: ':id/edit', element: <PaymentEditPage /> },
          { path: 'new', element: <PaymentCreatePage /> },
        ],
      },
      {
        path: 'offers',
        children: [
          { element: <OffersProfilePage />, index: true },
          { path: 'profile', element: <OffersProfilePage /> },
          { path: 'cards', element: <OffersCardsPage /> },
          { path: 'list', element: <OffersListPage /> },
          { path: 'new', element: <OffersCreatePage /> },
          { path: ':id/edit', element: <OffersEditPage /> },
          { path: 'account', element: <OffersAccountPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      { path: 'chat', element: <ChatPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
