import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Divider, Fade, Grow, Stack, Typography, useTheme } from '@mui/material';
import { useUpdateServiceMutation } from 'src/store/Reducer/service';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { current } from '@reduxjs/toolkit';
import { useGetAllAgentsDrowdownQuery, useGetAllAgentsQuery } from 'src/store/Reducer/agents';

// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

// ----------------------------------------------------------------------
import { MuiColorInput } from 'mui-color-input';

// ----------------------------------------------------------------------

import ServicesListPage from 'src/pages/dashboard/services/list';
import { Navigate, useParams } from 'react-router-dom';
import AddNewVariants from './add-new-variants';
import { useBoolean } from 'src/hooks/use-boolean';
import Label from 'src/components/label';
import { isArray } from 'lodash';
import {
  useAddNewProductMutation,
  useAddNewSizeTypeMutation,
  useAddNewSizeValueMutation,
  useGetAllSizeQuery,
} from 'src/store/Reducer/products';

const variantNames = [
  'Title',
  'Size Type',
  'Size Value',
  'Color',
  'Total Stock',
  'Price / Item (AED)',
];

export default function UserCreateVariantsForm({ currentUser, open, onClose }) {
  const theme = useTheme();


  const { enqueueSnackbar } = useSnackbar();

  const quickCreate = useBoolean();

  const { data: allSize } = useGetAllSizeQuery();

  console.log('Size Data = ', allSize);

  const [addNewSizeType] = useAddNewSizeTypeMutation();
  const [addNewSizeValue] = useAddNewSizeValueMutation();

  const NewUserSchema = Yup.object().shape({
    sizeType1: Yup.string(),
    sizeType2: Yup.string()
      .required('Size Type is required')
      .typeError('Size Type 2 must be a number'),
    sizeValue: Yup.string().required('Size value is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const newData = {
        type: data.sizeType2,
        value: data.sizeValue,
      };



      const response = await addNewSizeValue(newData);

      console.log('Response = ', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Size Value Created Successfully!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleTypeSubmit = async () => {
    try {
      const newData = {
        title: values.sizeType1,
      };

      console.log(newData);

      const response = await addNewSizeType(newData);

      console.log('Response = ', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset({ sizeType1: '' });
        enqueueSnackbar('Type Created Successfully!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Create Size Type</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            mt={1}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="sizeType1" label="Set Size Type" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleTypeSubmit}>
            Create Type
          </Button>
        </DialogActions>

        <DialogTitle mt={-6}>Create Sizes Value</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            mt={1}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFSelect name="sizeType2" label="Choose Size Type">
              
              {Array.isArray(allSize) &&
                allSize?.map((iter, index) => {
                  return (
                    <MenuItem key={index} value={iter._id}>
                      {iter.title}
                    </MenuItem>
                  );
                })}
            </RHFSelect>

            <RHFTextField name="sizeValue" label="Size Value" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Create Value
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserCreateVariantsForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
