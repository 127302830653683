import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const campaignsApi = createApi({
  reducerPath: 'campaigns',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Campaigns', 'AdminCampaigns'],
  endpoints: (builder) => ({
    getAllCampaigns: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/compaigns?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => res.body,
      providesTags: ['Campaigns'],
    }),

    getCampaigns: builder.query({
      query: () => `/compaigns/admin`,
      transformResponse: (res) => res.body,
      providesTags: ['AdminCampaigns'],
    }),

    // getServiceById: builder.query({
    //   query: (id) => `/services/${id}`,
    //   transformResponse: (res) => res.body,
    //   providedTags: ['Services'],
    // }),

    addNewCampaigns: builder.mutation({
      query: (newService) => ({
        url: `/compaigns`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newService,
      }),
      invalidatesTags: ['AdminCampaigns'],
    }),

    updateCampaigns: builder.mutation({
      query: ({ _id, updatedData }) => (
        console.log('updatedData',updatedData),{
        url: `/compaigns/${_id}`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['AdminCampaigns'],
    }),
  }),
});

export const {
  useGetAllCampaignsQuery,
  useAddNewCampaignsMutation,
  useGetCampaignsQuery,
  useUpdateCampaignsMutation,
} = campaignsApi;
