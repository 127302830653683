import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS, _userAbout, _userCards } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import { useGetAllServiceQuery } from 'src/store/Reducer/service';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { LoadingScreen } from 'src/components/loading-screen';
import UserNewEditForm from '../user-new-edit-form';
import { useGetAllProductQuery } from 'src/store/Reducer/products';
import { Box, LinearProgress } from '@mui/material';
import { useRef } from 'react';
import UserCreateVariantsForm from '../user-create-variants-form';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'title', label: 'Title' },
  { id: 'category', label: 'Category' },
  { id: 'price', label: 'Price' },
  { id: 'totalStock', label: 'Total Stock' },
  { id: 'description', label: 'Description' },
  { id: 'gender', label: 'Gender' },
  { id: 'status', label: 'Status' },
  { id: '', label: '' },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ServicesListView() {
  const { user } = useMockedUser();
  const { branch } = useMockedBranch();

  const printRef = useRef(null);

  const [filters, setFilters] = useState(defaultFilters);

  const table = useTable();

  const {
    data: { data, total_length } = {},
    isLoading,
    status,
  } = useGetAllProductQuery({ pageno: table.page, limit: table.rowsPerPage, search: filters.name, branch: branch?._id });

  const settings = useSettingsContext();

  const quickCreate = useBoolean();
  const quickSizeCreate = useBoolean();

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!total_length && canReset) || !total_length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  let count = 0;

  let total = table.page * table.rowsPerPage;

  const handlePrint = () => {
    // Get the specific content to print
    const printContents = printRef.current.innerHTML;

    // Save the original body content
    const originalContents = document.body.innerHTML;

    // Temporarily set the body's content to the specific content
    document.body.innerHTML = printContents;

    const tds = document.querySelectorAll('td');
    tds.forEach(td => {
      td.style.color = "black"
    });

    // Trigger the print dialog
    window.print();

    // Restore the original body content
    document.body.innerHTML = originalContents;

    // Optionally, reload the page to ensure everything is back to normal
    window.location.reload();
  };

  function PathRedirect(panel, tab) {
    return panel === "dashboard" ? paths.dashboard.business.profile + '/' + branch._id + `/${tab}` : paths.partner.business.profile + '/' + branch._id + `/${tab}`
  }


  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
              heading="Inventory List"
              links={[
                { name: 'Dashboard', href: user?.user_type === 2 ? paths.partner.root : paths.dashboard.root },
                { name: 'Products',  href: user?.user_type === 2 ? PathRedirect('partner', 'products') : PathRedirect('dashboard', 'products') },
                { name: 'List' },
              ]}
              action={
                user?.user_type === 2 && (
                  <Box sx={{ display: 'flex', gap: '.5rem', flexWrap: 'wrap', flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Button
                      component={RouterLink}
                      variant="contained"
                      onClick={() => quickCreate.onTrue()}
                      startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                      New Product
                    </Button>
                    <Button
                      component={RouterLink}
                      variant="contained"
                      color='primary'
                      onClick={() => quickSizeCreate.onTrue()}
                      startIcon={<Iconify icon="mingcute:add-line" />}
                    >
                      Set Variants Size
                    </Button>
                  </Box>
                )
              }
              sx={{
                mb: { xs: 3, md: 5 },
              }}
            />
            <Card>
              <UserTableToolbar
                filters={filters}
                onPrint={handlePrint}
                onFilters={handleFilters}
                roleOptions={_roles}
              />

              {canReset && (
                <UserTableFiltersResult
                  filters={filters}
                  onFilters={handleFilters}
                  onResetFilters={handleResetFilters}
                  results={total_length}
                  sx={{ p: 2.5, pt: 0 }}
                />
              )}

              <TableContainer sx={{ position: 'relative', overflow: 'unset', zIndex: 100 }}>
                <Scrollbar ref={printRef}>
                  {status === 'pending' ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                      <TableHeadCustom headLabel={TABLE_HEAD} rowCount={total_length} />
                      <TableBody>
                        {data?.map(
                          (row, index) => (
                            (count = count + 1),
                            (
                              <UserTableRow
                                key={row.id}
                                row={row}
                                selected={table.selected.includes(row.id)}
                                index={index + 1}
                                counter={count + total}
                              />
                            )
                          )
                        )}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(table.page, table.rowsPerPage, total_length)}
                        />
                        {total_length >= 1 ? '' : <TableNoData notFound={notFound} />}
                      </TableBody>
                    </Table>
                  )}
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={total_length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
              />
            </Card>
            {quickCreate.value && (
              <UserNewEditForm open={quickCreate.value} onClose={quickCreate.onFalse} />
            )}

            {quickSizeCreate.value && (
              <UserCreateVariantsForm open={quickSizeCreate.value} onClose={quickSizeCreate.onFalse} />
            )}
          </Container>
        </>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;

  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user && user.name && user.name.toLowerCase()?.indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }

  return inputData;
}
