import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const SettingsApi = createApi({
  reducerPath: 'settings',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['about', 'privacy', 'tac', 'contacts'],
  endpoints: (builder) => ({
    getAbout: builder.query({
      query: ({ type }) => ({
        url: `admin/about?type=${type}`,
        method: 'GET',
        transformResponse: (res) => res.data,
      }),
      providesTags: ['about'],
    }),

    getAllContacts: builder.query({
      query: ({ search, pageno, limit }) => ({
        url: `/admin/contact?search=${search}&pageno=${pageno + 1}&limit=${limit}`,
        method: 'GET',
        transformResponse: (res) => res.data,
      }),
      providesTags: ['contacts'],
    }),

    // REPLY CONTACT QUERY FROM ADMIN END POINT
    replyContactQuery: builder.mutation({
      query: ({ _id, reply }) => ({
        url: `/admin/contact/${_id}`,
        method: 'PUT',
        body: {
          reply,
        },
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['contacts'],
    }),

    // NEW END POINT
    sendContactQuery: builder.mutation({
      query: ({ name, email, phoneNumber, subject, message }) => ({
        url: `/admin/contact`,
        method: 'POST',
        body: {
          name,
          email,
          phoneNumber,
          subject,
          message,
        },
      }),
      transformResponse: (res) => res.data,
      invalidatesTags: ['contacts'],
    }),

    updateAbout: builder.mutation({
      query: ({ text, type }) => {
        return {
          url: `admin/about?type=${type}`,
          method: 'PUT',
          body: { about: text },
        };
      },
      invalidatesTags: ['about'],
    }),

    getPrivacy: builder.query({
      query: ({ type }) => ({
        url: `admin/privacy?type=${type}`,
        method: 'GET',
        transformResponse: (res) => res.data,
      }),
      providesTags: ['privacy'],
    }),

    updatePrivacy: builder.mutation({
      query: ({ text, type }) => {
        return {
          url: `admin/privacy?type=${type}`,
          method: 'PUT',
          body: { privacy: text },
        };
      },
      invalidatesTags: ['privacy'],
    }),

    getTerms: builder.query({
      query: ({ type }) => ({
        url: `/admin/tac?type=${type}`,
        method: 'GET',
        transformResponse: (res) => res.data,
      }),
      providesTags: ['tac'],
    }),

    updateTerms: builder.mutation({
      query: ({ text, type }) => {
        return {
          url: `admin/tac?type=${type}`,
          method: 'PUT',
          body: { tac: text },
        };
      },
      invalidatesTags: ['tac'],
    }),
    getTac: builder.query({
      query: () => ({
        url: `admin/tac`,
        method: 'GET',
        transformResponse: (res) => res.data,
      }),
    }),
  }),
});

export const {
  useGetAboutQuery,
  useUpdateAboutMutation,
  useSendContactQueryMutation,
  useGetPrivacyQuery,
  useUpdatePrivacyMutation,
  useGetTermsQuery,
  useUpdateTermsMutation,
  useGetAllContactsQuery,
  useReplyContactQueryMutation,
  useGetTacQuery,
} = SettingsApi;
