import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Typography } from '@mui/material';
import { useUpdateServiceMutation } from 'src/store/Reducer/service';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { current } from '@reduxjs/toolkit';
import { useGetAllAgentsDrowdownQuery, useGetAllAgentsQuery } from 'src/store/Reducer/agents';

// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

// ----------------------------------------------------------------------
import { MuiColorInput } from 'mui-color-input';


import { useUpdateProductMutation } from 'src/store/Reducer/products';


export default function UserQuickEditForm({ currentUser, open, onClose }) {
  const [AgentsForPayload, setAgentsForPayload] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const { data: categoryData, isLoading } = useGetAllCategoryQuery();

  const [uploadNewFile] = useUploadNewFileMutation();
  const [updateProduct] = useUpdateProductMutation();
  const [deleteFile] = useDeleteFileMutation();

  const NewUserSchema = Yup.object().shape({
    image: Yup.mixed().required('Image is required').nullable(),
    title: Yup.string().required('Title is required'),
    price: Yup.number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable(),
    totalStock: Yup.number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable(),
    description: Yup.string().required('Description is required'),
    gender: Yup.string().required('Gender is required'),
    category: Yup.string().required('Category is required'),
    isActive: Yup.boolean().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      image: currentUser?.images[0] || null,
      title: currentUser?.title || '',
      price: currentUser?.price || '',
      totalStock: currentUser?.totalStock || '',
      category: currentUser?.category?._id || '',
      description: currentUser?.description || '',
      isActive: currentUser?.isActive || '',
      gender: currentUser?.gender || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log('Watch URL ----> ', values);

  useEffect(() => {
    setValue('isActive', currentUser?.isActive);
    setValue('price', currentUser?.price);
    setValue('totalStock', currentUser?.totalStock);
    setValue('category', currentUser?.category?._id);
    setValue('description', currentUser?.description);
    setValue('image', currentUser?.images[0]);
    setValue('title', currentUser?.title);
    setValue('gender', currentUser?.gender);
  }, [currentUser]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      let iconUpdate =
        currentUser?.images[0] &&
        currentUser?.images[0].slice(40, currentUser?.images[0].length);

      let picname = iconUpdate;
      if (typeof data?.image === 'object') {
        await deleteFile(picname);
        const result = await uploadNewFile(data?.image);

        iconUpdate = result.data?.body?.fileName;
      }
      const newData = {
        title: data.title,
        description: data.description,
        category: data.category,
        price: data.price ? data.price : 0,
        totalStock: data.totalStock ? data.totalStock : 0,
        isActive: data.isActive,
        gender: data.gender,
        images: [iconUpdate],
      };

      console.log("currentUser = ", currentUser);

      console.log("newData = ", newData);

      let response = await updateProduct({
        _id: currentUser?._id,
        updatedData: newData,
      });

      console.log('Response =', response);
      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        await new Promise((resolve) => setTimeout(resolve, 500));
        reset();
        onClose();
        enqueueSnackbar('Update success!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      // console.log('newFile----------', newFile);

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const [colorValue, setColorValue] = useState('#ffffff');

  const handleChangeColor = (newValue) => {
    setColorValue(newValue);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Update Product</DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 5, mt: 5 }}>
            <RHFUploadAvatar
              name="image"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                ></Typography>
              }
            />
          </Box>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="title" label="Name" />
            <RHFSelect name="category" label="Category">
              {Array.isArray(categoryData) &&
                categoryData?.map((iter, index) => {
                  return (
                    <MenuItem key={index} value={iter._id}>
                      {iter.name}
                    </MenuItem>
                  );
                })}
            </RHFSelect>

            {currentUser?.price && currentUser.totalStock ? <>
              <RHFTextField helperText="(Optional)" name="price" label="Price" />
              <RHFTextField helperText="(Optional)" name="totalStock" label="Total Stock" />
            </> : ''}
            <RHFSelect name="gender" label="Gender">
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
              <MenuItem value={'Any'}>Any</MenuItem>
            </RHFSelect>
            <RHFSelect name="isActive" label="Status">
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFSelect>
            <RHFTextField name="description" label="Description" multiline rows={4} />

          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
