import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { paths } from 'src/routes/paths';

import { isOverflown } from '@mui/x-data-grid/utils/domUtils';
import { useRouter } from 'src/routes/hooks';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { fShortenNumber } from 'src/utils/format-number';
// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  index,
  counter,
}) {
  const { user } = useMockedUser();

  const { title, price, picture, description, category, isActive } = row;

  const router = useRouter();
  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const params = useParams();

  const popover = usePopover();

  const handleClick = () => {
    console.log(!!row?.variants[0]?.price);

    console.log(row);
  };

  return (
    <>
      <TableRow hover selected={selected} onClick={handleClick}>
        <TableCell>{counter}</TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar variant="rounded" alt={'Picture'} src={row.images[0]} sx={{ mr: 2 }} />

          <ListItemText
            primary={row?.title}
            secondary={''}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label marginRight=".2rem" variant="soft" color={'primary'}>
            {row?.category?.name}
          </Label>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {!!row?.variants[0]?.price ? (
            <Tooltip title="View" placement="top" arrow>
              <IconButton
                onClick={() =>
                  router.push(
                    `${paths.partner.business.profile}/${params.businessID}/products/${row?._id}`
                  )
                }
                color={'inherit'}
              >
                <Iconify width={25} icon="solar:money-bag-outline" />
              </IconButton>
            </Tooltip>
          ) : (
            row?.currency?.code + ' ' + fShortenNumber(row?.price) 
          )}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {!!row?.variants[0]?.totalStock ? (
            <Tooltip title="View" placement="top" arrow>
              <IconButton
                onClick={() =>
                  router.push(
                    `${paths.partner.business.profile}/${params.businessID}/products/${row?._id}`
                  )
                }
                color={'inherit'}
              >
                <Iconify width={25} icon="solar:database-outline" />
              </IconButton>
            </Tooltip>
          ) : (
            row?.totalStock
          )}
        </TableCell>

        <TableCell
          sx={{ whiteSpace: 'nowrap' }}
          style={{
            maxWidth: '15rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Tooltip title={row.description} placement="top-start">
            {row.description}
          </Tooltip>
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            '::first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {row.gender}
        </TableCell>

        <TableCell>
          <Label variant="soft" color={(isActive && 'success') || 'warning'}>
            {isActive ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {user?.user_type === 2 && (
            <Tooltip title="Quick Edit" placement="top" arrow>
              <IconButton
                color={quickEdit.value ? 'inherit' : 'default'}
                onClick={quickEdit.onTrue}
              >
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="View Profile" placement="top" arrow>
            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={() =>
                router.push(
                  `${paths.partner.business.profile}/${params.businessID}/products/${row?._id}`
                )
              }
            >
              <Iconify icon="solar:eye-bold" width={24} />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      {quickEdit.value && (
        <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
