import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { format } from 'date-fns';
import { useRouter } from 'src/routes/hooks';
import { Link } from 'react-router-dom';
import { color } from 'framer-motion';
import { display, fontWeight } from '@mui/system';
// ----------------------------------------------------------------------
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { paths } from 'src/routes/paths';
import { useState, useCallback } from 'react';
// @mui
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import { Alert, Container, Grid, Stack, useTheme } from '@mui/material';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { m } from 'framer-motion';
// import Calendar3D from "public/assets/Calendar_3d.jpg"
import Calendar3D from '../../assets/Calendar_3d.jpg';
import Open from '../../assets/available.png';
import Close from '../../assets/unavailable.png';
import { PartnerTeamMemberStatusMenu } from 'src/components/partnerTeamMemberStatusMenu';
// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  counter,
}) {
  const theme = useTheme();

  const { user } = useMockedUser();

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const [state, setState] = React.useState(true);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = () => {
    console.log(row);
  };

  const list = (anchor) => (
    <Container component={MotionViewport}>
      <Grid container alignItems="center">
        <Grid xs={12} md={7} lg={7} alignItems="center">
          <m.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img
              src={Calendar3D}
              style={{
                maxWidth: '90%',
                borderRadius: '.5rem',
                margin: '1rem',
              }}
            />
          </m.div>
        </Grid>
        <Grid xs={12} md={5} lg={5}>
          {row?.openingHours.length >= 1 ? (
            row?.openingHours.map((iter) => (
              <Card
                key={iter.id}
                onKeyDown={toggleDrawer(anchor, false)}
                onClick={toggleDrawer(anchor, false)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                  boxShadow: 3,
                }}
              >
                <img
                  className="iconBox"
                  style={{ borderRadius: '.5rem', padding: '.4rem' }}
                  alt="icon"
                  src={iter?.isOpen ? Open : Close}
                  width={'70rem'}
                />

                <Box sx={{ marginLeft: '.4rem' }}>
                  <Typography
                    component="div"
                    variant="h4"
                    fontWeight={800}
                    color={theme.palette.primary.light}
                    textAlign="center"
                  >
                    {iter.day}
                  </Typography>
                  <Stack
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    margin={'.5rem'}
                  >
                    <Iconify
                      icon="solar:clock-circle-bold"
                      sx={{ flexShrink: 0, marginRight: '.3rem' }}
                    />
                    {`${iter.startTime} - ${iter.endTime}`}
                  </Stack>
                </Box>
              </Card>
            ))
          ) : (
            <Alert
              onKeyDown={toggleDrawer(anchor, false)}
              onClick={toggleDrawer(anchor, false)}
              severity="warning"
              sx={{ margin: '.5rem' }}
            >
              Fields not set.
            </Alert>
          )}
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <>
      <TableRow hover selected={selected} onClick={handleClick}>
        <TableCell>{counter}</TableCell>

        <TableCell sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
          <Avatar alt={row?.user.name} src={row?.user?.image} sx={{ mr: 2 }} />

          <ListItemText
            primary={row?.user?.name}
            secondary={row?.user?.email}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.user?.mobile}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.jobTitle}</TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            '::first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {row?.user?.gender}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <React.Fragment key={'top'}>
            <Button variant="contained" onClick={toggleDrawer('top', true)}>
              {'View'}
            </Button>
            <Drawer
              backgroundColor="#7955cf"
              sx={{
                '@media (max-width: 476px)': {
                  width: '20rem',
                },
                '.MuiPaper-root': {
                  borderRadius: '.5rem',
                  marginTop: '.5rem',
                },
              }}
              anchor={'top'}
              open={state['top']}
              onClose={toggleDrawer('top', false)}
            >
              {list('top')}
            </Drawer>
          </React.Fragment>
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={(row.availableForHomeService === true ? 'success' : 'error') || 'default'}
          >
            {row.availableForHomeService === true ? 'Available' : 'Unavailable'}
          </Label>
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={(row.isActive === true ? 'success' : 'error') || 'default'}
          >
            {row.isActive === true ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>
{/* 
        <TableCell>
          <PartnerTeamMemberStatusMenu row={row} />
        </TableCell> */}

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {user?.user_type === 2 && (
            <>
              {' '}
              <Tooltip title="Quick Edit" placement="top" arrow>
                <IconButton
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={quickEdit.onTrue}
                >
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Tooltip>
              <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </>
          )}
        </TableCell>
      </TableRow>

      {quickEdit.value && (
        <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
