import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { ListSubheader, Paper, styled, Typography, useTheme } from '@mui/material';
import { useUpdateServiceMutation } from 'src/store/Reducer/service';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { current } from '@reduxjs/toolkit';
import { useGetAllAgentsDrowdownQuery, useGetAllAgentsQuery } from 'src/store/Reducer/agents';

// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

// ----------------------------------------------------------------------

import { useMockedBranch } from 'src/hooks/use-mocked-branch';


export default function UserQuickEditForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedSubcategory, setSelectedSubcategory] = useState({});

  const theme = useTheme();

  const { branch } = useMockedBranch();

  const { data: CategoriesData } = useGetAllCategoryQuery();

  const [uploadNewFile] = useUploadNewFileMutation();
  const [deleteFile] = useDeleteFileMutation();

  const [updateService] = useUpdateServiceMutation();


  const integerValidation = (value) => {
    if (!Number.isInteger(value)) {
      return false;
    }
    return true;
  };


  const NewUserSchema = Yup.object().shape({
    picture: Yup.mixed().required('Image is required').nullable(),
    title: Yup.string().required('Title is required'),
    subcategory: Yup.string().required('Subcategory is required'),
    description: Yup.string().required('Description is required'),
    gender: Yup.string().required('Gender is required'),
    time: Yup.number().test('time', 'Please enter an integer value', integerValidation),
    price: Yup.number().test('price', 'Please enter an integer value', integerValidation),
    isActive: Yup.boolean().required('Status is required'),
  });

  const defaultValues = useMemo(
    () => ({
      picture: currentUser?.images[0] || null,
      title: currentUser?.title || '',
      price: currentUser?.price || 0,
      description: currentUser?.description || '',
      isActive: currentUser?.isActive || '',
      time: currentUser?.time || '',
      subcategory: currentUser?.subcategory?._id,
      gender: currentUser?.gender,
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    setValue('picture', currentUser?.images[0]);
    setValue('subcategory', currentUser?.subcategory?._id);
    setValue('title', currentUser?.title);
    setValue('description', currentUser?.description);
    setValue('price', currentUser?.price);
    setValue('isActive', currentUser?.isActive);
    setValue('time', currentUser?.time);
    setValue('gender', currentUser?.gender);
  }, [currentUser]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      let iconUpdate =
        currentUser?.images[0] &&
        currentUser?.images[0].slice(40, currentUser?.images[0].length);

      let picname = iconUpdate;
      if (typeof data?.picture === 'object') {
        await deleteFile(picname);
        const result = await uploadNewFile(data?.picture);

        iconUpdate = result.data?.body?.fileName;
      }
      const newData = {
        title: data.title,
        description: data.description,
        category: selectedSubcategory?.categoryID,
        subcategory: selectedSubcategory?.subcategoryID,
        time: data.time,
        price: data.price,
        branch: branch?._id,
        images: [iconUpdate],
        gender: data.gender,
        isActive: data.isActive,
      };


      let response = await updateService({ _id: currentUser?._id, updatedData: newData });
      console.log('Response = ', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Updated Successfully!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('picture', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleChange = (event) => {
    let foundCategory = null;
    let foundSubcategory = null;

    CategoriesData?.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        if (subcategory._id === event.target.value) {
          foundCategory = category;
          foundSubcategory = subcategory;
        }
      });
    });

    setSelectedSubcategory({ categoryID: foundCategory._id, subcategoryID: event.target.value });
  };


  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Update Service</DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 5, mt: 5 }}>
            <RHFUploadAvatar
              name="picture"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                ></Typography>
              }
            />
          </Box>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="title" label="Name" />



            <RHFSelect
              name="subcategory"
              onChange={handleChange}
              label="Choose Category"
              value={selectedSubcategory.subcategoryID && selectedSubcategory.subcategoryID}
            >
              <MenuItem disabled value="">
                <em>Choose Category</em>
              </MenuItem>
              {CategoriesData?.flatMap((category, index) => [
                <ListSubheader
                  key={`header-${index}`}
                  sx={{
                    border: `1px solid ${theme.palette.primary.main}`,
                    fontWeight: 600,
                    m: '0.2rem',
                    borderRadius: '.5rem',
                  }}
                >
                  {category.name}
                </ListSubheader>,
                category.subcategories.length >= 1
                  ? category.subcategories.map((subcategory, subIndex) => (
                    <MenuItem
                      sx={{ paddingLeft: '2rem', paddingTop: '.5rem', fontStyle: 'italic', fontSize: '.8rem' }}
                      key={`${index}-${subIndex}`}
                      value={subcategory._id}
                    >

                      {subcategory.name}

                    </MenuItem>
                  ))
                  :
                  <span style={{ paddingLeft: '2rem', paddingTop: '.5rem', fontStyle: 'italic', fontSize: '.8rem', color : '#e53935'}}>
                    No Data
                  </span>
              ])}
            </RHFSelect>
            <RHFTextField name="time" label="Service Duration" />
            <RHFTextField name="price" label="Price" />
            <RHFSelect name="gender" label="Gender">
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
              <MenuItem value={'Any'}>Any</MenuItem>
            </RHFSelect>

            <RHFSelect name="isActive" label="Status">
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFSelect>
            <RHFTextField name="description" label="Description" multiline rows={4} />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};