import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from 'src/routes/components';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { whitespace } from 'stylis';
import { useBoolean } from 'src/hooks/use-boolean';
import UserNewSignUpForm from './user-new-signup';
import { m } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { Box } from '@mui/material';
import { useLocales } from 'src/locales';
// ----------------------------------------------------------------------

export default function SignupButton({ sx }) {
  const customStyles = {
    ...sx,
    borderColor: 'primary.main',
    color: 'white',
    // marginLeft: '60px',
    backgroundColor: '#6e44cd',
    whitespace: 'nowrap',
    '&:hover': {
      borderColor: 'primary.dark',
      backgroundColor: '#6e44cd',
    },
  };

  const { t } = useLocales();
  const quickSignup = useBoolean();

  return (
    <>
      <Button
        component={RouterLink}
        onClick={quickSignup.onTrue}
        size="md"

        // variant="outlined"
        sx={{ ...customStyles, }}
      >
        {t('Pre-Signup')}
      </Button>
      {quickSignup.value && (

        <UserNewSignUpForm open={quickSignup.value} onClose={quickSignup.onFalse} />

      )
      }
    </>
  );
}

SignupButton.propTypes = {
  sx: PropTypes.object,
};
