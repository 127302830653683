import { Helmet } from 'react-helmet-async';
import TestView from '../sections/test/test-view';
// sections


// ----------------------------------------------------------------------

export default function StatusPage() {
  return (
    <>
      <Helmet>
        <title>Status</title>
      </Helmet>
      <TestView />
    </>
  );
}
