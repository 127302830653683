// @mui
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// _mock
import {
  _ecommerceNewProducts,
  _ecommerceSalesOverview,
  _ecommerceBestSalesman,
  _ecommerceLatestProducts,
} from 'src/_mock';
// components
import { useSettingsContext } from 'src/components/settings';
// assets
import { MotivationIllustration } from 'src/assets/illustrations';
//
import EcommerceWelcome from '../ecommerce-welcome';
import EcommerceNewProducts from '../ecommerce-new-products';
import EcommerceYearlySales from '../ecommerce-yearly-sales';
import EcommerceBestSalesman from '../ecommerce-best-salesman';
import EcommerceSaleByGender from '../ecommerce-sale-by-gender';
import EcommerceSalesOverview from '../ecommerce-sales-overview';
import EcommerceWidgetSummary from '../ecommerce-widget-summary';
import EcommerceLatestProducts from '../ecommerce-latest-products';
import EcommerceCurrentBalance from '../ecommerce-current-balance';
import {
  useGetBusinessAnalyticsDashboardQuery,
  useGetBusinessDashboardQuery,
} from 'src/store/Reducer/dashboard';
import CustomDateRangePicker, { useDateRangePicker } from 'src/components/custom-date-range-picker';
import ComponentBlock from 'src/sections/_examples/component-block';
import { fDate } from 'src/utils/format-time';
import { Stack } from '@mui/system';

// ----------------------------------------------------------------------

export default function OverviewEcommerceView() {
  const { user } = useMockedUser();

  const theme = useTheme();

  const settings = useSettingsContext();

  const { data } = useGetBusinessDashboardQuery();

  const { data: chartData, isLoading } = useGetBusinessAnalyticsDashboardQuery();

  console.log('chartData', chartData);

  const rangeCalendarPicker = useDateRangePicker(new Date(), null);

  // const bookingsData = chartData
  //   ? [chartData.totalBookings || 0]
  //   : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  // const cancelledData = chartData
  //   ? [chartData.cancelledBookings || 0]
  //   : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const bookingsData = chartData ? Array(12).fill(chartData.totalBookings || 0) : Array(12).fill(0);
  const cancelledData = chartData
    ? Array(12).fill(chartData.cancelledBookings || 0)
    : Array(12).fill(0);

  console.log(data);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <EcommerceWelcome
            title={`Welcome! \n ${user?.displayName}`}
            description={""
              // "We are thrilled to have you on board and excited to continue our journey together. This dashboard is designed to keep you informed and empowered with the tools and insights needed to succeed. Let's work together to achieve great things and drive mutual growth."
            }
            img={<MotivationIllustration />}
            action={<></>
              // <Button variant="contained" color="primary">
              //   Go Now
              // </Button>
            }
          />
        </Grid>

        {/* <Grid xs={12} md={4}>
          <EcommerceNewProducts list={_ecommerceNewProducts} />
        </Grid> */}

        <Grid xs={12} md={4}>
          <EcommerceWidgetSummary
            title="Total Bookings"
            // percent={2.6}
            total={data?.totalBookings}
            chart={{
              series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
            }}
          />
        </Grid>

        <Grid xs={12} md={4}>
          <EcommerceWidgetSummary
            title="Cancelled Bookings"
            // percent={-0.1}
            total={data?.cancelledBookings}
            chart={{
              colors: [theme.palette.error.light, theme.palette.error.main],
              series: [56, 47, 40, 62, 73, 30, 23, 54, 67, 68],
            }}
          />
        </Grid>

        <Grid xs={12} md={4}>
          <EcommerceWidgetSummary
            title="Total Earnings"
            // percent={0.6}
            total={data?.totalEarnings.toFixed(2)}
            chart={{
              colors: [theme.palette.warning.light, theme.palette.warning.main],
              series: [40, 70, 75, 70, 50, 28, 7, 64, 38, 27],
            }}
          />
        </Grid>

        <Grid xs={12} md={12} lg={12}>
          {!isLoading && (
            <EcommerceYearlySales
              title="Bookings Report"
              chart={{
                categories: [
                  'Jan',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                  'Oct',
                  'Nov',
                  'Dec',
                ],
                series: [
                  {
                    year: '2024',
                    data: [
                      {
                        name: 'Bookings',
                        data: [chartData?.totalBookings || 0],
                      },
                      {
                        name: 'Cancelled',
                        data: [chartData?.cancelledBookings || 0],
                      },
                    ],
                  },
                ],
              }}
            />
          )}
        </Grid>

        {/* <Grid xs={12} md={12} lg={12}>
          <EcommerceYearlySales
            title="Bookings Report"
            chart={{
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],

              series: [
                {
                  year: '2019',
                  data: [
                    {
                      name: 'Bookings',
                      data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 35, 51, 49],
                    },
                    {
                      name: 'Cancelled',
                      data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 13, 56, 77],
                    },
                  ],
                },
                {
                  year: '2020',
                  data: [
                    {
                      name: 'Bookings',
                      data: [51, 35, 41, 10, 91, 69, 62, 148, 91, 69, 62, 49],
                    },
                    {
                      name: 'Cancelled',
                      data: [56, 13, 34, 10, 77, 99, 88, 45, 77, 99, 88, 77],
                    },
                  ],
                },
              ],
            }}
          />
        </Grid> */}

        {/* <Grid xs={12} md={6} lg={4}>
          <EcommerceSaleByGender
            title="Sale By Gender"
            total={2324}
            chart={{
              series: [
                { label: 'Mens', value: 44 },
                { label: 'Womens', value: 75 },
              ],
            }}
          />
        </Grid> */}

        {/* <Grid xs={12} md={6} lg={8}>
          <EcommerceYearlySales
            title="Yearly Sales"
            subheader="(+43%) than last year"
            chart={{
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              series: [
                {
                  year: '2019',
                  data: [
                    {
                      name: 'Total Income',
                      data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 35, 51, 49],
                    },
                    {
                      name: 'Total Expenses',
                      data: [10, 34, 13, 56, 77, 88, 99, 77, 45, 13, 56, 77],
                    },
                  ],
                },
                {
                  year: '2020',
                  data: [
                    {
                      name: 'Total Income',
                      data: [51, 35, 41, 10, 91, 69, 62, 148, 91, 69, 62, 49],
                    },
                    {
                      name: 'Total Expenses',
                      data: [56, 13, 34, 10, 77, 99, 88, 45, 77, 99, 88, 77],
                    },
                  ],
                },
              ],
            }}
          />
        </Grid>

        <Grid xs={12} md={6} lg={8}>
          <EcommerceSalesOverview title="Sales Overview" data={_ecommerceSalesOverview} />
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <EcommerceCurrentBalance
            title="Current Balance"
            currentBalance={187650}
            sentAmount={25500}
          />
        </Grid>

        <Grid xs={12} md={6} lg={8}>
          <EcommerceBestSalesman
            title="Best Salesman"
            tableData={_ecommerceBestSalesman}
            tableLabels={[
              { id: 'name', label: 'Seller' },
              { id: 'category', label: 'Product' },
              { id: 'country', label: 'Country', align: 'center' },
              { id: 'totalAmount', label: 'Total', align: 'right' },
              { id: 'rank', label: 'Rank', align: 'right' },
            ]}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <EcommerceLatestProducts title="Latest Products" list={_ecommerceLatestProducts} />
        </Grid> */}
      </Grid>
    </Container>
  );
}
