import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Typography } from '@mui/material';

// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

// ----------------------------------------------------------------------
import { MuiColorInput } from 'mui-color-input';

// ----------------------------------------------------------------------

import ServicesListPage from 'src/pages/dashboard/services/list';
import { Navigate } from 'react-router-dom';
import { useGetAllSizeQuery, useUpdateVariantMutation } from 'src/store/Reducer/products';

const categories = ['Barber', 'Face Treatments', 'Nails'];

const subcategories = ['Dress shoes', 'Athletic shoes', 'Flats', 'Heels', 'Loafers', 'Boots'];

export default function UpdateVariant({ productData, currentUser, open, onClose }) {
  console.log('currentUser = ', currentUser);
  console.log('productData = ', productData);

  const { data: allSize } = useGetAllSizeQuery();

  // console.log('allSize, ', allSize);

  const { enqueueSnackbar } = useSnackbar();

  const [colorValue, setColorValue] = useState(currentUser?.color);

  const [updateVariant] = useUpdateVariantMutation();

  const handleChangeColor = (newValue) => {
    setColorValue(newValue);
  };

  const NewUserSchema = Yup.object().shape({
    variantTitle: Yup.string().required('Title is required'),
    price: Yup.number().required('Price is required').positive('Price must be a positive number'),
    totalStock: Yup.string().required('Total Stock is required'),
    sizeType: Yup.string().required('Size Type is required'),
    sizeValue: Yup.string().required('Size Value is required'),
    color: Yup.string().required('Color is required'),
  });

  const defaultValues = useMemo(
    () => ({
      variantTitle: currentUser?.variantTitle || '',
      price: currentUser?.price || 0,
      sizeType: currentUser?.sizeType?.title || '',
      sizeValue: currentUser?.sizeValue?.value || '',
      color: currentUser?.color || '',
      totalStock: currentUser?.totalStock || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  // console.log('Watch URl------> ', values);

  const valueArray = useMemo(() => {
    const foundItem = allSize?.find((iter) => iter._id === values.sizeType);
    return foundItem ? foundItem.values : undefined;
  }, [allSize, values.sizeType]);

  // console.log('valueArray = ', valueArray);

  useEffect(() => {
    setValue('variantTitle', currentUser?.variantTitle);
    setValue('sizeType', currentUser?.sizeType?._id);
    setValue('sizeValue', currentUser?.sizeValue?._id);
    setValue('price', currentUser?.price);
    setValue('color', currentUser?.color);
    setValue('totalStock', currentUser?.totalStock);
  }, [currentUser]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const newData = {
        variantTitle: data.variantTitle,
        sizeType: data.sizeType,
        sizeValue: data.sizeValue,
        color: colorValue,
        totalStock: data.totalStock,
        price: data.price,
      };

      console.log('New Data = ', productData);

      let response = await updateVariant({
        product_id: productData?._id,
        variant_id: currentUser?._id,
        updatedData: newData,
      });

      console.log('Response =', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        await new Promise((resolve) => setTimeout(resolve, 500));
        reset();
        onClose();
        enqueueSnackbar('Update success!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Update Variant</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            marginTop={'.5rem'}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="variantTitle" label="Variant Title" />

            <RHFSelect name="sizeType" label="Size Type">
              {allSize?.map((iter) => (
                <MenuItem value={iter?._id}>{iter?.title}</MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect name="sizeValue" label="Size Value">
              {valueArray?.map((iter, index) => {
                return (
                  <MenuItem key={index} value={iter._id}>
                    {iter.value}
                  </MenuItem>
                );
              })}
            </RHFSelect>
            <MuiColorInput format="hex" value={colorValue} onChange={handleChangeColor} />
            <RHFTextField name="totalStock" label="Total Stock" />
            <RHFTextField name="price" label="Price" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UpdateVariant.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
