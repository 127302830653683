import { useState, useCallback } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// _mock
import {
  _userAbout,
  _userFeeds,
  _userFriends,
  _userGallery,
  _userFollowers,
  _appFeatured,
  _ecommerceNewProducts,
} from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProfileHome from '../profile-home';
import ProfileCover from '../profile-cover';
import ProfileFriends from '../profile-friends';
import ProfileGallery from '../profile-gallery';
import ProfileFollowers from '../profile-followers';
import AppWelcome from 'src/sections/overview/app/app-welcome';
import AppFeatured from 'src/sections/overview/app/app-featured';
import { OverviewEcommerceView } from 'src/sections/overview/e-commerce/view';
import NewProducts from '../overview-new-products';
import {
  Alert,
  Badge,
  Box,
  ButtonGroup,
  CardActions,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  makeStyles,
  styled,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { RHFTextField } from 'src/components/hook-form';
import Label from 'src/components/label';
import { fontSize, width } from '@mui/system';

import { position } from 'stylis';
import { useBoolean } from 'src/hooks/use-boolean';
import UserNewEditForm from '../user-new-edit-form';
import CreateVariant from '../create-variant';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useGetInventoryProductsByIdQuery,
  useGetProductByIdQuery,
} from 'src/store/Reducer/products';
import { useParams } from 'react-router-dom';
import UpdateVariant from '../update-variant';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
// ----------------------------------------------------------------------

const variantNames = ['Size Type', 'Size Value', 'Color', 'Total Stock', 'Price / Item (AED)'];

export default function ProductsProfileView() {
  const settings = useSettingsContext();
  const theme = useTheme();

  const params = useParams();

  const { data, status } = useGetInventoryProductsByIdQuery(params.productsID);

  const { user } = useMockedUser();

  const { branch } = useMockedBranch();

  const quickVariantCreate = useBoolean();

  const quickVariantUpdate = useBoolean();

  const confirm = useBoolean();

  const [searchFriends, setSearchFriends] = useState('');

  const [currentTab, setCurrentTab] = useState('profile');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const handleSearchFriends = useCallback((event) => {
    setSearchFriends(event.target.value);
  }, []);

  function PathRedirect(panel, tab) {
    return panel === 'dashboard'
      ? paths.dashboard.business.profile + '/' + branch._id + `/${tab}`
      : paths.partner.business.profile + '/' + branch._id + `/${tab}`;
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <CustomBreadcrumbs
        heading="Inventroy Details"
        links={[
          {
            name: 'Dashboard',
            href: user?.user_type === 2 ? paths.partner.root : paths.dashboard.root,
          },
          {
            name: 'Products',
            href:
              user?.user_type === 2
                ? PathRedirect('partner', 'products')
                : PathRedirect('dashboard', 'products'),
          },
          { name: 'Profile' },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <NewProducts list={data} status={status} />

      <Card
        sx={{
          marginTop: 1,
          display: 'flex',
          flexDirection: 'column',
          p: (theme) => theme.spacing(2, 2, 3, 3),
        }}
      >
        <Stack spacing={2}>
          {status === 'pending' ? (
            <Skeleton variant="rounded" width={'6rem'} height={'3.5rem'} />
          ) : (
            <Typography
              variant="h5"
              sx={{
                '::first-letter': {
                  textTransform: 'uppercase',
                },
              }}
            >
              {data?.title}
            </Typography>
          )}

          {status === 'pending' ? (
            <Skeleton variant="rounded" maxWidth={'100%'} height={'3rem'} />
          ) : (
            <Typography variant="body2">{data?.description}</Typography>
          )}

          <Box>
            <Badge
              color="info"
              badgeContent={
                data?.variants?.[0] && 'price' in data?.variants[0] ? data?.variants.length : '0'
              }
              showZero
            >
              <Button variant="contained" color={'inherit'}>
                Total Variants
              </Button>
            </Badge>
          </Box>

          {data?.gender === 'male' ? (
            <Button
              startIcon={
                <Iconify sx={{ marginLeft: '.3rem' }} width={24} icon="solar:men-bold-duotone" />
              }
              sx={{
                width: '6rem',
                padding: 1,
                '::first-letter': {
                  textTransform: 'uppercase',
                },
              }}
              variant="soft"
              color="warning"
            >
              {data?.gender}
            </Button>
          ) : data?.gender === 'female' ? (
            <Button
              startIcon={
                <Iconify sx={{ marginLeft: '.3rem' }} width={24} icon="solar:women-bold-duotone" />
              }
              sx={{
                width: '6rem',
                padding: 1,
                '::first-letter': {
                  textTransform: 'uppercase',
                },
              }}
              variant="soft"
              color="success"
            >
              {data?.gender}
            </Button>
          ) : (
            <Button
              startIcon={
                <Iconify
                  sx={{ marginLeft: '.3rem' }}
                  width={24}
                  icon="solar:hamburger-menu-outline"
                />
              }
              sx={{
                width: '6rem',
                padding: 1,
                '::first-letter': {
                  textTransform: 'uppercase',
                },
              }}
              variant="soft"
              color="info"
            >
              {data?.gender}
            </Button>
          )}

          <Typography variant="h5">
            {data?.variants?.[0] && 'price' in data?.variants[0] ? 'Variants List' : ''}
          </Typography>

          {data?.variants?.length === 0 && <Alert severity="error"> No variants added yet</Alert>}

          <Container sx={{ marginTop: '1rem' }}>
            {status === 'pending' ? (
              <Skeleton variant="rounded" maxWidth={'100%'} height={'15rem'} />
            ) : (
              <Grid container spacing={3}>
                {data?.variants?.[0] &&
                  'price' in data?.variants[0] &&
                  data?.variants?.map((iter, index) => {
                    return (
                      <Grid
                        key={index}
                        xs={12}
                        md={5}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          alignItems: 'space-between',
                          backgroundColor:
                            theme.palette.mode === 'dark'
                              ? '#263238'
                              : theme.palette.primary.lighter,
                          padding: '1rem 0 1rem 0',
                          borderRadius: '1rem',
                          margin: '.3rem',
                        }}
                      >
                        <Box
                          sx={{
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            margin: '0 .5rem -1.5rem 1.5rem',
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              '::first-letter': {
                                textTransform: 'uppercase',
                              },
                            }}
                            noWrap={true}
                          >
                            {iter.variantTitle}
                          </Typography>
                          {user?.user_type === 2 && (
                            <Box>
                              <IconButton>
                                <Iconify
                                  onClick={() => quickVariantUpdate.onTrue()}
                                  width={20}
                                  sx={{ cursor: 'pointer' }}
                                  icon="solar:pen-bold"
                                />
                              </IconButton>
                              <IconButton>
                                <Iconify
                                  onClick={() => confirm.onTrue()}
                                  width={20}
                                  sx={{ color: '#ef5350', cursor: 'pointer' }}
                                  icon="solar:trash-bin-trash-bold"
                                />
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                        <Stack
                          direction="row"
                          justifyContent={'center'}
                          borderRadius=".5rem"
                          spacing={2}
                          divider={
                            <Divider
                              orientation="vertical"
                              flexItem
                              sx={{ borderStyle: 'dashed', color: '#616161' }}
                            />
                          }
                          sx={{
                            py: 2,
                            backgroundColor: theme.palette.primary.lighter,
                            color: '#616161',
                          }}
                        >
                          <Box sx={{ padding: '.5rem', maxWidth: '15rem' }}>
                            {variantNames?.map((iter) => (
                              <Typography sx={{ margin: '.5rem' }}>{iter}</Typography>
                            ))}
                          </Box>
                          <Box sx={{ padding: '.5rem' }}>
                            <>
                              <Typography sx={{ margin: '.5rem' }}>
                                {iter?.sizeType?.title}
                              </Typography>
                              <Typography sx={{ margin: '.5rem' }}>
                                {iter?.sizeValue?.value}
                              </Typography>
                              <Typography
                                sx={{
                                  whiteSpace: 'nowrap',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                noWrap={true}
                              >
                                <Iconify
                                  icon="solar:pallete-2-bold"
                                  width={24}
                                  color={iter?.color}
                                />
                                <span style={{ marginLeft: '5px', fontSize: '.9rem' }}>
                                  {iter?.color}
                                </span>
                              </Typography>
                              <Typography sx={{ margin: '.5rem' }}>{iter?.totalStock}</Typography>
                              <Typography sx={{ margin: '.5rem' }}>
                                {data?.currency?.code + ' ' + iter?.price}
                              </Typography>
                            </>
                          </Box>
                        </Stack>
                        {quickVariantUpdate.value && (
                          <UpdateVariant
                            productData={data}
                            currentUser={iter}
                            open={quickVariantUpdate.value}
                            onClose={quickVariantUpdate.onFalse}
                          />
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Container>

          {quickVariantCreate && (
            <CreateVariant
              productData={data}
              open={quickVariantCreate.value}
              onClose={quickVariantCreate.onFalse}
            />
          )}
          <ConfirmDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title="Delete"
            content="Are you sure want to delete?"
            action={
              <Button variant="contained" color="error">
                Delete
              </Button>
            }
          />
        </Stack>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {user?.user_type === 2 && (
            <ButtonGroup>
              <Button onClick={() => quickVariantCreate.onTrue()}>Create More Variants </Button>
            </ButtonGroup>
          )}
        </CardActions>
      </Card>
    </Container>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  color: theme.palette.text.secondary,
}));
