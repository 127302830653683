import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const landingPageApi = createApi({
  reducerPath: 'landingPage',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['landingPage'],
  endpoints: (builder) => ({
    getAllSponsorships: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/sponsorships?pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => res.body,
      // providesTags: ['Services'],
    }),
    addNewSposnorShips: builder.mutation({
      query: (newService) => ({
        url: `/sponsorships`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newService,
      }),
    }),

    sendNewsletter: builder.mutation({
      query: (data) => ({
        url: `/newsletter/subscribe`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: data,
      }),
    }),
  }),
});

export const { useAddNewSposnorShipsMutation, useGetAllSponsorshipsQuery, useSendNewsletterMutation } = landingPageApi;
