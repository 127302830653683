import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify/iconify';
import { CardMedia } from '@mui/material';

export default function DownloadButtons(props) {

  const theme = useTheme();


  const { t } = useLocales();
  return (
    <Box sx={{ width: '180px', padding: '0px' }}>
      <Card
      onClick={props.onClick}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          backgroundColor: 'black',
          justifyContent: 'center',
          border: `1px solid ${theme.palette.text.primary} `,
          //   borderBottom: '2px solid #bfbfbf',
          padding : '.7rem 0rem',
          textAlign: 'center',
        }}
      >
        <CardMedia
          sx={{
            marginRight : '.4rem',
            width: '32px',
            height: '34px',
            color: '#FFFFFF',
          }}
          image={props.image}
          title="Play Store"
        />

        <Typography
          variant="body2"
          sx={{
            minWidth : "100px",
            color: '#fafafa',
            fontSize: {
              xs: '.9rem',
            },
          }}
        >
          {props.description}
          <br />
          <Typography
            // sx={{
            //   fontSize: {
            //     xs: '1rem',
            //   },
            // }}
            variant="h6"
            component="span"
          >
            <b>{props.title}</b>
          </Typography>
        </Typography>
      </Card>
    </Box>
  );
}
