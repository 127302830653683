import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import { borderRadius } from '@mui/system';
import { useReplyContactQueryMutation } from 'src/store/Reducer/settings';

// ----------------------------------------------------------------------

export default function SupportReplyForm({ currentUser, open, onClose, row }) {
  const { enqueueSnackbar } = useSnackbar();

  const [sendContactReply] = useReplyContactQueryMutation();

  const integerValidation = (value) => {
    if (!Number.isInteger(value)) {
      return false;
    }
    return true;
  };

  const NewUserSchema = Yup.object().shape({
    reply: Yup.string().required('Reply is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    // Pre-fill the reply field with the existing reply if available
    if (row?.reply) {
      setValue('reply', row.reply);
    }
  }, [row, setValue]);

  const values = watch();

  console.log(values);

  const onSubmit = handleSubmit(async (data) => {
    try {
      const newData = {
        reply: data.reply,
        _id: row?._id,
      };

      console.log('newData = ', newData);

      let response = await sendContactReply(newData);
      console.log('Response = ', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        enqueueSnackbar('Success!');
        // reset({ reply: '' });
      }
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Reply</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            sx={{ marginTop: '.5rem' }}
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <RHFTextField
              name="reply"
              label="Message"
              style={{
                borderRadius: '10px',
              }}
              multiline
              rows={5}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" color="primary" loading={isSubmitting}>
            Send
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

SupportReplyForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
