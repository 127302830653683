import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import {
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFUploadAvatar,
  RHFRadioGroup,
} from 'src/components/hook-form';
import TeamMemberTimingSlots from './team-members-timing-slots';
import { useEffect } from 'react';
import { current } from '@reduxjs/toolkit';
import { useUpdateMembersMutation } from 'src/store/Reducer/members';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function UserQuickEditForm({ currentUser, open, onClose }) {
  const { enqueueSnackbar } = useSnackbar();

  console.log("currentUser = ", currentUser);
  
  const [openingHoursArray, setOpeningHoursArray] = useState(currentUser?.openingHours);

  const handleFilters = useCallback((name, value, index) => {
    setOpeningHoursArray((prevState) => {
      const newState = [...prevState];

      newState[index] = {
        ...newState[index],
        [name]: value,
      };

      return newState;
    });
  }, []);

  const [updateMembers] = useUpdateMembersMutation();
  const [deleteFile] = useDeleteFileMutation();
  const [uploadFile] = useUploadNewFileMutation();

  useEffect(() => {
    setValue('name', currentUser?.user?.name);
    setValue('email', currentUser?.user?.email);
    setValue('gender', currentUser?.user?.gender);
    setValue('mobile', currentUser?.user?.mobile);
    setValue('image', currentUser?.user?.image);
    setValue('jobTitle', currentUser?.jobTitle);
    setValue('availableForHomeService', currentUser?.availableForHomeService);
    setValue('isActive', currentUser?.isActive);
  }, [currentUser]);

  const NewUserSchema = Yup.object().shape({
    image: Yup.mixed().nullable().required('Avatar is required'),
    name: Yup.string().required('Name is required'),
    mobile: Yup.string().required('Phone number is required'),
    availableForHomeService: Yup.boolean().required('HomeService is required'),
    isActive: Yup.boolean().required('HomeService is required'),
    jobTitle: Yup.string().required('Job Title is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.user?.name || '',
      gender: currentUser?.user?.gender || '',
      mobile: currentUser?.user?.mobile || '',
      image: currentUser?.user?.image || '',
      jobTitle: currentUser?.jobTitle || '',
      availableForHomeService: currentUser?.availableForHomeService || '',
      isActive: currentUser?.isActive || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch();


  const onSubmit = handleSubmit(async (data) => {
    try {
      let iconUpdate =
        currentUser?.user?.image &&
        currentUser?.user?.image.slice(40, currentUser?.user?.image.length);

      let picname = iconUpdate;
      if (typeof data?.image === 'object') {
        await deleteFile(picname);
        const result = await uploadFile(data?.image);

        iconUpdate = result.data?.body?.fileName;
      }

      const newData = {
        image: iconUpdate,
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        availableForHomeService: data.availableForHomeService,
        isActive: data.isActive,
        jobTitle: data.jobTitle,
        gender: data.gender,
        openingHours: openingHoursArray,
      };

      console.log('newData',newData);
      
      let response = await updateMembers({
        _id: currentUser?._id,
        updatedData: newData,
      });

      await new Promise((resolve) => setTimeout(resolve, 500));
      if (response.error) {
        enqueueSnackbar(response.error.data.message, {
          variant: 'error',
          autoHideDuration: 1000,
        });
      } 
      else {
        enqueueSnackbar('Update Success!');
        onClose();
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Update Team Member</DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 5 }}>
            <RHFUploadAvatar
              name="image"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                ></Typography>
              }
            />
          </Box>
          <Box
            marginTop={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="name" label="Name" />
            <RHFTextField name="email" label="Email" />
            <RHFTextField name="mobile" label="Contact Number" />
            <RHFTextField name="jobTitle" label="Job Title" />

            <RHFSelect name="availableForHomeService" label="Home Service Availability">
              <MenuItem value={true}>Available</MenuItem>
              <MenuItem value={false}>Notavailable</MenuItem>
            </RHFSelect>

            <RHFSelect name="isActive" label="Active Status">
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </RHFSelect>

            
            <RHFRadioGroup
              row
              defaultValues={currentUser?.user?.gender}
              name="gender"
              label={'Gender'}
              options={[
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
              ]}
            />
          </Box>

          <Typography margin={'.7rem 0 0 .1rem '} variant="subtitle1">
            Select Slots
          </Typography>
          <Box
            marginTop={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            {currentUser?.openingHours?.map((iter, index) => {
              return (
                <TeamMemberTimingSlots
                  key={iter._id}
                  onFilters={handleFilters}
                  index={index}
                  data={iter}
                />
              );
            })}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
