import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { paths } from 'src/routes/paths';
import { isOverflown } from '@mui/x-data-grid/utils/domUtils';
import { format } from 'date-fns';
import SelectOffer from './select-offer';
import { Box, Typography, useTheme } from '@mui/material';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import SupportReplyForm from '../support-reply-form';
// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  index,
  counter,
}) {
  const handleClick = () => {
    console.log(row);
  };

  console.log('row',row);

  const quickCreate = useBoolean();

  return (
    <>
      <TableRow hover selected={selected} onClick={handleClick}>
        <TableCell>{counter}</TableCell>

        <TableCell>
          <ListItemText
            primary={row?.name}
            secondary={row?.email}
            primaryTypographyProps={{
              whiteSpace: 'nowrap',
              typography: 'body2',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              whiteSpace: 'nowrap',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.subject}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.message}</TableCell>

        <TableCell>
          <ListItemText
            primary={`${format(new Date(row?.createdAt), 'dd MMM yyyy')}`}
            secondary={`${format(new Date(row?.createdAt), 'p')}`}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Button variant="outlined" color="primary"  onClick={quickCreate.onTrue}>
            Reply
          </Button>
        </TableCell>
      </TableRow>

      <SupportReplyForm currentUser={''} row={row} open={quickCreate.value} onClose={quickCreate.onFalse} />

    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
