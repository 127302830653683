import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Divider, Fade, Grow, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useUpdateServiceMutation } from 'src/store/Reducer/service';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { current } from '@reduxjs/toolkit';
import { useGetAllAgentsDrowdownQuery, useGetAllAgentsQuery } from 'src/store/Reducer/agents';

// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

// ----------------------------------------------------------------------
import { MuiColorInput } from 'mui-color-input';

// ----------------------------------------------------------------------

import ServicesListPage from 'src/pages/dashboard/services/list';
import { Navigate, useParams } from 'react-router-dom';
import AddNewVariants from './add-new-variants';
import { useBoolean } from 'src/hooks/use-boolean';
import Label from 'src/components/label';
import { isArray } from 'lodash';
import { useAddNewProductMutation } from 'src/store/Reducer/products';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';

const variantNames = [
  'Title',
  'Size Type',
  'Size Value',
  'Color',
  'Total Stock',
  'Price / Item (AED)',
];

export default function UserNewEditForm({ currentUser, open, onClose }) {
  const theme = useTheme();

  const { branch } = useMockedBranch();

  const { enqueueSnackbar } = useSnackbar();

  const { data: categoryData, isLoading } = useGetAllCategoryQuery();
  const quickCreate = useBoolean();

  const [addNewProduct] = useAddNewProductMutation();

  const [variantArray, setVariantArray] = useState([]);

  const [uploadFile] = useUploadNewFileMutation();

  const NewUserSchema = Yup.object().shape({
    image: Yup.mixed().required('Image is required').nullable(),
    title: Yup.string().required('Title is required'),
    price: Yup.number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable(),
    totalStock: Yup.number()
      .transform((value, originalValue) => originalValue === '' ? null : value)
      .nullable(),
    description: Yup.string().required('Description is required'),
    gender: Yup.string().required('Gender is required'),
    category: Yup.object().required('Category is required'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const handleVariant = useCallback((obj) => {
    variantArray.push(obj);
  });

  console.log('variantArray = ', variantArray);

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log("values============> ", values);
  console.log("variantArray============> ", variantArray);


  const onSubmit = handleSubmit(async (data) => {
    // if (variantArray.length >= 1) {
    //   if(!data.price && !data.totalStock){

    //   }
    //   return enqueueSnackbar('Please fill price, totalstock or variants!', {
    //     variant: 'error',
    //   });
    // }
    try {
      const result = await uploadFile(data?.image);
      let iconUpdate = result.data?.body?.fileName;

      const newData = {
        title: data.title,
        description: data.description,
        category: data.category._id,
        price: data.price ? data.price : 0,
        totalStock: data.totalStock ? data.totalStock : 0,
        gender: data.gender,
        branch: branch?._id,
        images: [iconUpdate],
        variants: variantArray,
      };

      console.log("newData = ", newData);

      const response = await addNewProduct(newData);

      console.log('Response = ', response);
      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Product Created Successfully!');
        setVariantArray([]);
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  console.log("variantArray = ", variantArray);

  const handleDelete = (index) => {
    setVariantArray((prevState) => prevState.filter((_, i) => i !== index));
  }


  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Create Product</DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 5, mt: 5 }}>
            <RHFUploadAvatar
              name="image"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                ></Typography>
              }
            />
          </Box>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="title" label="Name" />
            <RHFSelect name="category" label="Category">
              {Array.isArray(categoryData) &&
                categoryData?.map((iter, index) => {
                  return (
                    <MenuItem key={index} value={iter}>
                      {iter.name}
                    </MenuItem>
                  );
                })}
            </RHFSelect>

            <RHFTextField helperText="(Optional)" name="price" label="Price" />
            <RHFTextField helperText="(Optional)" name="totalStock" label="Total Stock" />

            <RHFSelect name="gender" label="Gender">
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
              <MenuItem value={'Any'}>Any</MenuItem>
            </RHFSelect>
            <RHFTextField name="description" label="Description" multiline rows={4} />

          </Box>
          {variantArray?.map((iter, index) => {
            return (
              <Grow
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...(true ? { timeout: 500 } : {})}
              >
                <Box marginTop={'.5rem'} borderRadius=".5rem" sx={{ backgroundColor: theme.palette.primary.lighter, color: '#616161' }}>

                  <Box display={'flex'} justifyContent={'space-between'} marginBottom={'-2rem'}>
                    <span></span>
                    <IconButton variant="soft"
                      onClick={() => handleDelete(index)}
                    > <Iconify
                        width="2rem"
                        sx={{ color: '#ef5350', cusrsor: 'pointer' }}
                        icon="basil:cancel-solid"
                      />
                    </IconButton>
                  </Box>


                  <Stack
                    direction="row"
                    justifyContent={'center'}
                    spacing={2}
                    divider={
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ borderStyle: 'dashed', color: '#616161' }}
                      />
                    }
                  >
                    <Box sx={{ padding: '.5rem', maxWidth: '15rem' }}>
                      {variantNames?.map((iter) => (
                        <Typography sx={{ margin: '.5rem' }}>{iter}</Typography>
                      ))}
                    </Box>
                    <Box sx={{ padding: '.5rem' }}>
                      <>
                        <Typography
                          sx={{
                            margin: '.5rem',
                            '::first-letter': {
                              textTransform: 'uppercase',
                            },
                          }}
                        >
                          {iter.variantTitle}
                        </Typography>
                        <Typography sx={{ margin: '.5rem' }}>{iter.sizeType.title}</Typography>
                        <Typography sx={{ margin: '.5rem' }}>{iter.sizeValue.value}</Typography>
                        <Typography sx={{ margin: '.5rem' }}>{iter.color}</Typography>
                        <Typography sx={{ margin: '.5rem' }}>{iter.totalStock}</Typography>
                        <Typography sx={{ margin: '.5rem' }}>{iter.price}</Typography>
                      </>
                    </Box>
                  </Stack>
                </Box>


              </Grow>
            );
          })}
          <Stack sx={{ marginTop: '1rem' }} rowGap={3} columnGap={2} display="flex">
            <Button onClick={() => quickCreate.onTrue()} variant="contained">
              Add Variants
            </Button>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
        </DialogActions>
      </FormProvider>
      {quickCreate.value && (
        <AddNewVariants
          onSetVariants={handleVariant}
          open={quickCreate.value}
          onClose={quickCreate.onFalse}
        />
      )}
    </Dialog>
  );
}

UserNewEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
