import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
// components
import Image from 'src/components/image';
import Carousel, { CarouselDots, useCarousel } from 'src/components/carousel';
import Avatar from '@mui/material/Avatar';
import { ListItemText, Skeleton } from '@mui/material';
// ----------------------------------------------------------------------
export default function NewProducts({ list, status, ...other }) {
  const carousel = useCarousel({
    speed: 800,
    autoplay: true,
    ...CarouselDots({
      sx: {
        right: 20,
        bottom: 20,
        position: 'absolute',
        color: 'primary.light',
      },
    }),
  });

  return (
    <Card {...other}>
      {status === 'pending' ? (
        <Skeleton variant="rounded" maxWidth={'100%'} height={'17rem'} />
      ) : (
        <Carousel {...carousel.carouselSettings}>
          {list?.images?.map((iter, index) => (
            <CarouselItem item={list} index={index} />
          ))}
        </Carousel>
      )}
    </Card>
  );
}

{
}
NewProducts.propTypes = {
  list: PropTypes.array,
};

// ----------------------------------------------------------------------

function CarouselItem({ item, index }) {
  const theme = useTheme();
  const renderImg = (
    <Image
      alt={item?.name}
      src={item.images[index]}
      overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[100], 0)} 50%, ${
        theme.palette.grey[900]
      } 100%)`}
      sx={{
        overflow: 'hidden',
        width: 1,
        height: { xs: 280, xl: 320 },
      }}
    />
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <CardContent
        sx={{
          left: 0,
          width: 1,
          bottom: 0,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >
        <Avatar
          src={item?.images[index]}
          alt={''}
          sx={{
            width: { xs: 64, md: 90 },
            height: { xs: 64, md: 90 },
            border: `solid 2px ${theme.palette.common.white}`,
          }}
        />

        <ListItemText
          sx={{
            mt: 1,
            opacity: 0.8,
            textAlign: { xs: 'center', md: 'unset' },
          }}
          primary={item?.title}
          secondary={item?.category.name}
          primaryTypographyProps={{
            typography: 'h5',
          }}
          secondaryTypographyProps={{
            mt: 0.5,
            color: 'inherit',
            component: 'span',
            typography: 'body2',
            sx: { opacity: 0.5 },
          }}
        />
      </CardContent>

      {renderImg}
    </Box>
  );
}

CarouselItem.propTypes = {
  item: PropTypes.object,
};
