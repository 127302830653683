import React from 'react'
import AboutHero from '../about-hero'
import AboutWhat from '../about-what'
import { useGetPrivacyQuery } from 'src/store/Reducer/settings'

export default function PrivacyPolicyView() {
  const { data } = useGetPrivacyQuery({type : 2});
  return (
    <>
      <AboutHero data={{ title: "Our", list: ['Privacy Policy'] }} />
      <AboutWhat data={{ title: "", content: data?.body?.privacy, image: '/assets/images/about/Privacy_Policy.png' }} />
    </>
  )
}
