import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { LoadingButton } from '@mui/lab';
import { useGetPrivacyQuery, useUpdatePrivacyMutation } from 'src/store/Reducer/settings';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import FaqsHero from 'src/sections/faq/faqs-hero';
import { useLocales } from 'src/locales';
import { useLocation } from 'react-router-dom';
import { useMockedUser } from 'src/hooks/use-mocked-user';


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
}));

export default function PrivacyView() {
  const { data: CustomerData } = useGetPrivacyQuery({ type: 1 });
  const { data: BusinessData } = useGetPrivacyQuery({ type: 2 });

  const { pathname } = useLocation();


  const {user} = useMockedUser();
  
  const theme = useTheme();

  var data;

  let path = pathname.split('/').at(pathname.split('/').length - 1);

  path === 'customer' ? data = CustomerData : data = BusinessData


  const [updatePrivacyText] = useUpdatePrivacyMutation();

  const [text, setText] = useState('');

  const extractPlainText = (html) => {
    if (!html) return '';
    const textWithoutTags = html.replace(/<[^>]*>/g, '');
    const trimmedText = textWithoutTags.trim();
    const finalText = trimmedText.replace(/\n/g, ' ');
    return finalText;
  };

  useEffect(() => {
    if (data) {
      setText(data?.body?.privacy || '');
    }
  }, [data]);

  const handleChange = (value) => {
    setText(value);
  };

  const handleSave = async () => {
    try {
      let response = await updatePrivacyText({ text: text, type: path === 'customer' ? 1 : 2 });
      console.log("response = ", response);
      toast.success('Updated Successfully');
    } catch (error) {
      toast.error(error);
    }
  };

  const { t } = useLocales();

  return (
    <>
      {/* {!data ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography>
            Loading...
          </Typography>
        </Box>
      ) : ( */}
      <FaqsHero data={"Privacy & Policy"} />

      <Container maxWidth="xl" sx={{ marginTop: 4 }}>
        {/* <Typography variant="h4" gutterBottom>
          Privacy & Policy
        </Typography> */}

        <StyledPaper sx={{
          ".ql-toolbar": {
            backgroundColor: theme?.palette.mode === 'dark' ? "#d1c4e9" : ""
          }
        }} elevation={3}>
          <ReactQuill
            value={text ? text : `${t('Loading...')}`}
            onChange={handleChange}
            modules={{
              toolbar: [
                [{ header: '1' }, { header: '2' }, { font: [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['link', 'image', 'video'],
                ['clean'],
              ],
            }}
            formats={[
              'header',
              'font',
              'size',
              'bold',
              'italic',
              'underline',
              'strike',
              'blockquote',
              'list',
              'bullet',
              'indent',
              'link',
              'image',
              'video',
            ]}
          />
        </StyledPaper>
        {
          user?.user_type === 0 && <LoadingButton
            type="submit"
            variant="contained"
            sx={{ mt: '1rem', float: 'right' }}
            onClick={handleSave}
          >
            Save Privacy & Policy
          </LoadingButton>
        }
      </Container>
    </>
  );
}
