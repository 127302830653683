import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Stack, Typography } from '@mui/material';
import { useUpdateServiceMutation } from 'src/store/Reducer/service';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { current } from '@reduxjs/toolkit';
import { useGetAllAgentsDrowdownQuery, useGetAllAgentsQuery } from 'src/store/Reducer/agents';

// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

// ----------------------------------------------------------------------
import { MuiColorInput } from 'mui-color-input';

// ----------------------------------------------------------------------

import { Navigate } from 'react-router-dom';
import { useGetAllSizeQuery } from 'src/store/Reducer/products';

export default function AddNewVariants({ onSetVariants, open, onClose }) {

  const { data: allSize } = useGetAllSizeQuery();

  const [colorValue, setColorValue] = useState('#ffffff');

  const handleChangeColor = (newValue) => {
    setColorValue(newValue);
  };

  const NewUserSchema = Yup.object().shape({
    variantTitle: Yup.string().required('Title is required'),
    sizeType: Yup.object().required('Size Type is required'),
    sizeValue: Yup.object().required('Size Value is required'),
    totalStock: Yup.string().required('totalStock is required'),
    price: Yup.number().required('Price is required').positive('Price must be a positive number'),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const variantData = {
        variantTitle: data.variantTitle,
        sizeType: data.sizeType,
        sizeValue: data.sizeValue,
        color: colorValue,
        totalStock: data.totalStock,
        price: data.price,
      };

      onSetVariants(variantData);

      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      onClose();
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Add Variant</DialogTitle>

        <DialogContent>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
            sx={{ marginTop: '.5rem' }}
          >
            <RHFTextField name="variantTitle" label="Variant Title" />

            <RHFSelect name="sizeType" label="Size Type">
              {allSize?.map((iter) => (
                <MenuItem value={iter}>{iter?.title}</MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect name="sizeValue" label="Size Value">

              {Array.isArray(allSize) &&
                (values?.sizeType?.values?.length === 0 ? (
                  <MenuItem disabled>
                    {'No Data'}
                  </MenuItem>
                ) : (
                  values?.sizeType?.values?.map((iter, index) => (
                    <MenuItem key={index} value={iter}>
                      {iter.value}
                    </MenuItem>
                  ))
                ))
              }
             
            </RHFSelect>
            <MuiColorInput format="hex" value={colorValue} onChange={handleChangeColor} />
            <RHFTextField name="totalStock" label="Total Stock" />
            <RHFTextField name="price" label="Price" />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained">
            Add Variant
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

AddNewVariants.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
