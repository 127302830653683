import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Stack, Typography, useTheme } from '@mui/material';
import { useUpdateOfferTypeMutation, useUpdateServiceMutation } from 'src/store/Reducer/service';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { current } from '@reduxjs/toolkit';
import { useGetAllAgentsDrowdownQuery, useGetAllAgentsQuery } from 'src/store/Reducer/agents';

// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
// ----------------------------------------------------------------------
import { MuiColorInput } from 'mui-color-input';

// ----------------------------------------------------------------------

import ServicesListPage from 'src/pages/dashboard/services/list';
import { Navigate } from 'react-router-dom';
import { useGetAllSizeQuery } from 'src/store/Reducer/products';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

export default function SelectOffer({ currentUser, open, onClose }) {


  const theme = useTheme();

  const [updateOfferType] = useUpdateOfferTypeMutation();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    offerType: Yup.string().required('Offer type is required'),
    openfrom: Yup.date().required('Starting hours are required'),
    closedTo: Yup.date().required('Closing hours are required'),
    discount: Yup.number()
      .typeError("That doesn't look like a phone number")
      .required('Discount is required')
      .positive('Number must be positive'),
    validTill: Yup.date().required('Due Date is required'),
  });

  const defaultValues = useMemo(
    () => ({
      offerType: currentUser?.offer?.type || '',
      openfrom: currentUser?.offer?.start ? dayjs(currentUser?.offer?.start, 'HH:mm') : null,
      closedTo: currentUser?.offer?.end ? dayjs(currentUser?.offer?.end, 'HH:mm') : null,
      discount: currentUser?.offer?.discount || '',
      validTill: currentUser?.offer?.validTill ? dayjs(currentUser?.offer?.validTill) : null,
    }),
    [currentUser]
  );

  useEffect(() => {
    setValue('offerType', currentUser?.offer?.type);
    setValue('openfrom', dayjs(currentUser?.offer?.start, 'HH:mm'));
    setValue('closedTo', dayjs(currentUser?.offer?.end, 'HH:mm'));
    setValue('discount', currentUser?.offer?.discount);
    setValue('validTill', dayjs(currentUser?.offer?.validTill));
  }, [currentUser]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const values = watch();

  console.log('Watch URL -----', values);

  const onSubmit = handleSubmit(async (data) => {
    try {

      const newData = {
        type: data.offerType,
        start: format(new Date(data.openfrom), 'hh:mm'),
        end: format(new Date(data.closedTo), 'hh:mm'),
        discount: data.discount,
        validTill: format(new Date(data.validTill), 'yyyy-MM-dd'),
      };

      console.log('NewData = ', newData);

      let response = await updateOfferType({ _id: currentUser?._id, updatedData: newData });

      console.log('Response = ', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Updated Successfully!');
      }
    } catch (error) {
      console.error(error);
    }
  });



  const handleLimitOfYear = () => {
    let today = new Date();
    let numberOfDaysToAdd = 10;
    return format(
      new Date(today.setFullYear(today.getFullYear() + numberOfDaysToAdd)),
      "dd MMM yyyy"
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: '30rem' },
      }}
    >
      <Iconify
        width="3rem"
        ml="auto"
        mr="auto"
        mt={2}
        sx={{ textAlign: 'center' }}
        icon="solar:sale-bold-duotone"
        color={theme.palette.primary.main}
      />
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ textAlign: 'center' }} id="alert-dialog-title">
          {'Select Offer Type'}
        </DialogTitle>

        <DialogContent>
          <RHFSelect sx={{ marginTop: '.5rem' }} name="offerType" label="Choose Offer">
            <MenuItem value={'flashHours'}>Flash Hours</MenuItem>
            <MenuItem value={'flat'}>Flat</MenuItem>
          </RHFSelect>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '1rem' }}>
            <Controller
              name="openfrom"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    label="Open From"
                    {...field}
                    value={field?.value || null}
                    onChange={(newValue) => field.onChange(newValue)}
                    renderInput={(params) => <RHFTextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            <Controller
              name="closedTo"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileTimePicker
                    label="Closed To"
                    {...field}
                    value={field?.value || null}
                    onChange={(newValue) => field.onChange(newValue)}
                    renderInput={(params) => <RHFTextField {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
          </Box>

          <RHFTextField sx={{ marginTop: '1rem' }} name="discount" label="Discount" />

          <Controller
            name="validTill"
            control={control}
            defaultValue={defaultValues.validTill}
            render={({ field }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Due Date"
                  sx={{ marginTop: '.5rem' }}
                  {...field}
                  value={field?.value || null}
                  onChange={(newValue) => field.onChange(newValue)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      helperText: !!errors?.validTill && 'Please enter a valid date format for the due date.',
                      color: 'error',
                      error: !!errors?.validTill,
                      InputProps: {
                        style: {
                          borderColor: errors?.validTill ? 'red' : 'default',
                        },
                      },
                    },
                  }}
                  renderInput={(params) => <RHFTextField {...params} />}
                  minDate={dayjs(new Date())}
                  maxDate={dayjs(new Date(handleLimitOfYear()))}
                />
              </LocalizationProvider>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            loading={isSubmitting}
            type="submit"
            // onClick={handleClose}
            variant="contained"
            color="primary"
            size="medium"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

SelectOffer.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
