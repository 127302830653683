import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { PRODUCT_SIZE_OPTIONS, USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Typography } from '@mui/material';
import { useAddNewServiceMutation, useUpdateServiceMutation } from 'src/store/Reducer/service';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';
import { current } from '@reduxjs/toolkit';
import { useGetAllAgentsDrowdownQuery, useGetAllAgentsQuery } from 'src/store/Reducer/agents';
import { styled } from '@mui/system';
// MultiSelect CheckBox
// import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useGetAllCategoryQuery, useGetCategoriesDropdownQuery } from 'src/store/Reducer/category';

import ServicesListPage from 'src/pages/dashboard/services/list';
import { Navigate, useParams } from 'react-router-dom';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
import { TextareaAutosize } from '@mui/base';


export default function UserNewEditForm({ currentUser, open, onClose }) {

  const { enqueueSnackbar } = useSnackbar();


  const { branch } = useMockedBranch();

  const { data: categoryData, isLoading } = useGetAllCategoryQuery();

  const [uploadNewFile] = useUploadNewFileMutation();
  const [addNewService] = useAddNewServiceMutation();

  const integerValidation = (value) => {
    if (!Number.isInteger(value)) {
      return false;
    }
    return true;
  };

  const NewUserSchema = Yup.object().shape({
    picture: Yup.mixed().required('Image is required').nullable(),
    title: Yup.string().required('Title is required'),
    category: Yup.object().required('Category is required'),
    subcategory: Yup.string().required('Subcategory is required'),
    description: Yup.string().required('Description is required'),
    gender: Yup.string().required('Gender is required'),
    time: Yup.number().test('time', 'Please enter an integer value', integerValidation),
    price: Yup.number().test('price', 'Please enter an integer value', integerValidation),
  });

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
  });

  const {
    reset,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log("values = " , values);
  
  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await uploadNewFile(data?.picture);
      let iconUpdate = result?.data?.body?.fileName;


      const newData = {
        title: data.title,
        description: data.description,
        category: data.category._id,
        subcategory: data.subcategory,
        time: data.time,
        price: data.price,
        branch: branch?._id,
        images: [iconUpdate],
        gender: data.gender,
      };

      let response = await addNewService(newData);
      console.log('Response = ', response);

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Service Created Successfully!');
      }
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      // console.log('newFile----------', newFile);

      if (file) {
        setValue('picture', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  console.log(values?.category);


  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Create Service</DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 5, mt: 5 }}>
            <RHFUploadAvatar
              name="picture"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                ></Typography>
              }
            />
          </Box>

          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="title" label="Name" />
            <RHFSelect name="category" label="Category">
              {Array.isArray(categoryData) &&
                categoryData?.map((iter, index) => {
                  return (
                    <MenuItem key={index} value={iter}>
                      {iter.name}
                    </MenuItem>
                  );
                })}
            </RHFSelect>

            <RHFSelect name="subcategory" label="Subcategory">
              {Array.isArray(categoryData) &&
                (values?.category?.subcategories.length === 0 ? (
                  <MenuItem disabled>
                    {'No Data'}
                  </MenuItem>
                ) : (
                  values?.category?.subcategories.map((iter, index) => (
                    <MenuItem key={index} value={iter._id}>
                      {iter.name}
                    </MenuItem>
                  ))
                ))
              }

            </RHFSelect>
            <RHFTextField name="time" label="Service Duration" />
            <RHFTextField name="price" label="Price" />
            <RHFSelect name="gender" label="Gender">
              <MenuItem value={'Male'}>Male</MenuItem>
              <MenuItem value={'Female'}>Female</MenuItem>
              <MenuItem value={'Any'}>Any</MenuItem>
            </RHFSelect>
            <RHFTextField name="description" label="Description" multiline rows={4} />

          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Create
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserNewEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
