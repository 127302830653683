import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFAutocomplete,
  RHFUploadAvatar,
} from 'src/components/hook-form';
import { Typography } from '@mui/material';
import { useUpdateCategoryMutation } from 'src/store/Reducer/category';
import { status } from 'nprogress';
import { useDeleteFileMutation, useUploadNewFileMutation } from 'src/store/Reducer/file';

// ----------------------------------------------------------------------

export default function UserQuickEditForm({ currentUser, open, onClose }) {
  const [updateCategory] = useUpdateCategoryMutation();
  const [uploadFile] = useUploadNewFileMutation();
  const [deleteFile] = useDeleteFileMutation();
  const { enqueueSnackbar } = useSnackbar();

  console.log('currentUser = ', currentUser);
  const NewUserSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    image: Yup.mixed().nullable().required('Image is required'),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentUser?.name || '',
      image: currentUser?.image || null,
      status: currentUser?.isActive || '',
    }),
    [currentUser]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    setValue,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  console.log('Watch URL ---> ', values);

  useEffect(() => {
    setValue('title', currentUser?.name);
    setValue('image', currentUser?.image);
    setValue('status', currentUser?.isActive);
  }, [currentUser]);
  const onSubmit = handleSubmit(async (data) => {
    try {
      let iconUpdate =
        currentUser?.image && currentUser?.image.slice(40, currentUser?.image.length);
      let picname = iconUpdate;

      if (typeof data.image === 'object') {
        await deleteFile(picname);

        const result = await uploadFile(data.image);
        iconUpdate = result.data?.body?.fileName;
      }
      const newData = {
        name: data.title,
        image: iconUpdate,
        isActive: data.status,
      };
      const response = await updateCategory({ id: currentUser?._id, updatedData: newData });

      if (response.error) {
        enqueueSnackbar('Failed!', {
          variant: 'error',
          autoHideDuration: 500,
        });
      } else {
        reset();
        onClose();
        enqueueSnackbar('Updated Successfully!');
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    } catch (error) {
      console.error(error);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (file) {
        setValue('image', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  var x = 'https://timezzi-bucket.s3.amazonaws.com/';

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 720 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>Update SubCategory</DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 5, mt: 5 }}>
            <RHFUploadAvatar
              name="image"
              maxSize={3145728}
              onDrop={handleDrop}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 3,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.disabled',
                  }}
                ></Typography>
              }
            />
          </Box>
          <Box sx={{mb:1}} rowGap={3} columnGap={2} display="flex" justifyContent={'center'}>
            <RHFTextField name="title" label="Title" />
          </Box>

          <RHFSelect name="status" label="Status">
            <MenuItem value={true}>Active</MenuItem>
            <MenuItem value={false}>Inactive</MenuItem>
          </RHFSelect>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Update
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

UserQuickEditForm.propTypes = {
  currentUser: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
