import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from './Reducer/auth';
import userReducer from './slices/userSlice';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { productsApi } from './Reducer/products';
import { categoryApi } from './Reducer/category';
import { customersApi } from './Reducer/customers';
import { agentsApi } from './Reducer/agents';
import { serviceApi } from './Reducer/service';
import { equipmentApi } from './Reducer/equipment';
import { bookingApi } from './Reducer/booking';
import { FileApi } from './Reducer/file';
import { equipmentOrdersApi } from './Reducer/equipmentOrders';
import { specialOffersApi } from './Reducer/specialOffers';
import { FaqsApi } from './Reducer/faqs';
import { SettingsApi } from './Reducer/settings';
import { supportApi } from './Reducer/support';
import { dashboardApi } from './Reducer/dashboard';
import { businessApi } from './Reducer/business';
import { membersApi } from './Reducer/members';
import { subscriptionApi } from './Reducer/subscriptions';
import { subscriptionPlansApi } from './Reducer/subscriptions-plans';
import { membershipsApi } from './Reducer/memberships';
import { giftcardsApi } from './Reducer/gift-cards';
import { preSignupBusinessApi } from './Reducer/pre_signup_business';
import { discountsApi } from './Reducer/discounts';
import { paymentsApi } from './Reducer/payments';
import { campaignsApi } from './Reducer/campaigns';
import { adminGiftCardsApi } from './Reducer/admin-gift-cards';
import { landingPageApi } from './Reducer/landingpage';

// Define the persist configuration
const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['user'],
};

// Create a persisted reducer for the user slice
const persistedUserReducer = persistReducer(persistConfig, userReducer);
// const persistedBranchReducer = persistReducer(persistConfig, branchReducer);

// Create the Redux store with the persisted reducer for the user slice
export const store = configureStore({
  reducer: {
    [categoryApi.reducerPath]: categoryApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [agentsApi.reducerPath]: agentsApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [FileApi.reducerPath]: FileApi.reducer,
    [equipmentOrdersApi.reducerPath]: equipmentOrdersApi.reducer,
    [specialOffersApi.reducerPath]: specialOffersApi.reducer,
    [FaqsApi.reducerPath]: FaqsApi.reducer,
    [SettingsApi.reducerPath]: SettingsApi.reducer,
    [supportApi.reducerPath]: supportApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [businessApi.reducerPath]: businessApi.reducer,
    [membersApi.reducerPath]: membersApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [subscriptionPlansApi.reducerPath]: subscriptionPlansApi.reducer,
    [membershipsApi.reducerPath]: membershipsApi.reducer,
    [giftcardsApi.reducerPath]: giftcardsApi.reducer,
    [preSignupBusinessApi.reducerPath]: preSignupBusinessApi.reducer,
    [discountsApi.reducerPath]: discountsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [adminGiftCardsApi.reducerPath]: adminGiftCardsApi.reducer,
    [landingPageApi.reducerPath]: landingPageApi.reducer,

    [authApi.reducerPath]: authApi.reducer,
    user: persistedUserReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      productsApi.middleware,
      authApi.middleware,
      categoryApi.middleware,
      customersApi.middleware,
      agentsApi.middleware,
      serviceApi.middleware,
      equipmentApi.middleware,
      bookingApi.middleware,
      FileApi.middleware,
      equipmentOrdersApi.middleware,
      specialOffersApi.middleware,
      FaqsApi.middleware,
      SettingsApi.middleware,
      supportApi.middleware,
      dashboardApi.middleware,
      businessApi.middleware,
      membersApi.middleware,
      subscriptionApi.middleware,
      subscriptionPlansApi.middleware,
      membershipsApi.middleware,
      giftcardsApi.middleware,
      preSignupBusinessApi.middleware,
      discountsApi.middleware,
      paymentsApi.middleware,
      campaignsApi.middleware,
      adminGiftCardsApi.middleware,
      landingPageApi.middleware,
    ),
});

// Initialize Redux Persist
export const persistor = persistStore(store);

// Setup listeners
setupListeners(store.dispatch);
