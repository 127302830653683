import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ContactForm from '../contact-form';
import ContactHero from '../contact-hero';
import ContactFormPartner from '../contact-form-partner';

export default function ContactViewForm() {
  return (
    <>
      {/* <ContactHero /> */}

      <ContactHero />
      <Container sx={{ marginTop: '1rem' }}>
        <Box>
          <ContactFormPartner />
        </Box>
      </Container>
    </>
  );
}
