import { useState, useCallback, Suspense, Children, useEffect } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// _mock
import { _userAbout, _userFeeds, _userFriends, _userGallery, _userFollowers } from 'src/_mock';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProfileHome from '../profile-home';
import ProfileCover from '../profile-cover';
import ProfileFriends from '../profile-friends';
import ProfileGallery from '../profile-gallery';
import ProfileFollowers from '../profile-followers';

// ----------------------------------------------------------------------
import isEqual from 'lodash/isEqual';
// @mui
import { alpha } from '@mui/material/styles';

import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import { useParams, useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';

import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';

import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import { width } from '@mui/system';
import { MembersListView } from 'src/sections/members/view';
import { ServicesListView } from 'src/sections/services/view';
import { ProductsListView } from 'src/sections/products/view';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';

import BusinessMain from 'src/layouts/dashboard/business-main';
// ----------------------------------------------------------------------
import { NavSectionHorizontal } from 'src/components/nav-section';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
// ----------------------------------------------------------------------

export default function BusinessProfileView({ children }) {
  const settings = useSettingsContext();


  const router = useRouter();

  const {branch} = useMockedBranch();

  let location = useLocation();

  const [currentTab, setCurrentTab] = useState('');



  useEffect(() => {
    const setTab = location.pathname.slice(19, location.length);

    const check = TABS.map((iter) => {
      if (location.pathname.includes(iter.value)) {
        return setCurrentTab(iter.value);
      } else return;
    });
  }, [location]);
 

  const { user } = useMockedUser();

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'lg'}>
      <Stack direction="row" spacing={2}>
        <Avatar
          alt="image"
          src={branch?.image}
          sx={{ marginTop: '.5rem', width: 56, height: 56 }}
        />
        <CustomBreadcrumbs
          heading={branch?.name}
          links={
            user?.user_type === 2
              ? [{ name: 'Dashboard', href: paths.partner.root }, { name: branch?.name }]
              : [
                  { name: 'Dashboard', href: paths.dashboard.root },
                  { name: 'Business', href: paths.dashboard.business.root },
                  { name: branch?.name },
                ]
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
      </Stack>

      <Card
        sx={{
          mb: 1,
          pt: 1,
        }}
      >
        <Tabs value={currentTab} onChange={handleChangeTab} scrollButtons={true}>
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              icon={tab.icon}
              label={tab.label}
              onClick={() =>{
               user?.user_type === 2
                  ? router.push(`${paths.partner.business.profile}/${branch._id}/${tab.value}`)
                  : router.push(`${paths.dashboard.business.profile}/${branch._id}/${tab.value}`)
              }}
            />
          ))}
        </Tabs>
      </Card>
      <Box
        sx={{
          mb: 1,
          pt: 2,
        }}
      >
        {children}
      </Box>
    </Container>
  );
}

const TABS = [
  {
    value: 'profile',
    label: 'Business Info',
    icon: <Iconify icon="solar:user-id-bold" width={24} />,
  },
  {
    value: 'members',
    label: 'Team Members',
    icon: <Iconify icon="solar:clipboard-list-bold-duotone" width={24} />,
  },
  {
    value: 'vacations',
    label: 'Business Hours',
    icon: <Iconify icon="simple-icons:yourtraveldottv" width={24} />,
  },
  {
    value: 'services',
    label: 'Services',
    icon: <Iconify icon="solar:folder-favourite-star-bold-duotone" width={24} />,
  },
  {
    value: 'products',
    label: 'Inventory',
    icon: <Iconify icon="solar:bedside-table-4-bold-duotone" width={24} />,
  },
  {
    value: 'memberships',
    label: 'Memberships',
    icon: <Iconify icon="solar:cardholder-bold-duotone" width={24} />,
  },
  {
    value: 'payment',
    label: 'Payments',
    icon: <Iconify icon="solar:bill-check-bold-duotone" width={24} />,
  },
  {
    value: 'booking',
    label: 'Bookings',
    icon: <Iconify icon="solar:case-bold-duotone" width={24} />,
  },

  {
    value: 'discounts',
    label: 'Discounts',
    icon: <Iconify icon="solar:sale-bold-duotone" width={24} />,
  },
  {
    value: 'giftcards',
    label: 'Gift Cards',
    icon: <Iconify icon="solar:gift-bold-duotone" width={24} />,
  },
  {
    value: 'campaigns',
    label: 'Campaigns',
    icon: <Iconify icon="octicon:goal-24" width={24} />,
  },
  {
    value: 'qrcode',
    label: 'QR Code',
    icon: <Iconify icon="solar:qr-code-bold-duotone" width={24} />,
  },
];