import { m } from 'framer-motion';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// components
import { MotionViewport, varFade } from 'src/components/animate';
import ContactTable from './ContactTable';
import { useGetAllContactMessagesQuery } from 'src/store/Reducer/support';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { CircularProgress, TableContainer } from '@mui/material';
import { TableSelectedAction } from 'src/components/table';
import { _userList, _userAddressBook } from 'src/_mock';
import { Box } from '@mui/system';
import { useGetAllContactsQuery } from 'src/store/Reducer/settings';
import ContactListView from './view/contact-list-view';
import ContactUsForm from './contact-us-form';
// ----------------------------------------------------------------------

export default function ContactForm() {

  return (
    <Stack component={MotionViewport} spacing={5}>
      <ContactListView />
      {/* <ContactUsForm /> */}
    </Stack>
  );
}
