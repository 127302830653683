import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { usePopover } from './custom-popover';
import { ConfirmDialog } from './custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { useUpdateStatusMutation } from 'src/store/Reducer/business';
import { enqueueSnackbar } from 'notistack';
// import { Label } from '@mui/icons-material';
import { Box } from '@mui/system';
import Label from './label';

export function FadeMenu({ row }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [status, setstatus] = React.useState('');
  const open = Boolean(anchorEl);
  const [updateStatus] = useUpdateStatusMutation();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirm = useBoolean();

  const handleMenuClose = () => {
    setAnchorEl(null); // Only close the menu here
  };

  const handleConfirm = (selectedStatus) => {
    setstatus(selectedStatus);
    console.log('selectedStatus', selectedStatus);
    handleMenuClose();
    confirm.onTrue();
  };

  const handleSubmit = async () => {
    try {
      let response = await updateStatus({
        _id: row?._id,
        isActive: status === 'true' ? true : false,
      });

      if (response.error) {
        enqueueSnackbar(response.error.data.message, {
          variant: 'error',
          autoHideDuration: 1000,
        });
      } else {
        enqueueSnackbar('Update Success!');
        await new Promise((resolve) => setTimeout(resolve, 500));
        confirm.onFalse();
        // handleUndo();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div>
        {/* <Button
          id="fade-button"
          variant="outlined"
          style={{
            backgroundColor: '#F5EFFD',
            color: '#421B9E',
          }}
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Active
        </Button> */}
        <Box>
          <Label
            onClick={handleClick}
            variant="soft"
            color={(row.isActive && 'success') || 'error'}
          >
            {row.isActive ? 'Active' : 'Inactive'}
          </Label>
        </Box>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={() => handleConfirm('true')}>Active</MenuItem>

          <MenuItem onClick={() => handleConfirm('false')}>Inactive</MenuItem>
        </Menu>
      </div>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Change Status!"
        content="Are you sure want to change the current status"
        action={
          <Button
            variant="outlined"
            onClick={handleSubmit}
            style={{
              backgroundColor: '#F5EFFD',
              color: '#421B9E',
            }}
          >
            Sure
          </Button>
        }
      />
    </>
  );
}

export function Approved() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const confirm = useBoolean();

  const handleClose = () => {
    setAnchorEl(null);
    confirm.onTrue();
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Only close the menu here
  };

  const handleConfirm = () => {
    handleMenuClose(); // First close the menu
    confirm.onTrue(); // Then open the confirm dialog
  };

  return (
    <>
      <div>
        <Button
          id="fade-button"
          variant="outlined"
          color="success"
          aria-controls={open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Approved
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleConfirm}>Approved</MenuItem>

          <MenuItem onClick={handleConfirm}>Unapproved</MenuItem>
        </Menu>
      </div>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Change Status!"
        content="Are you sure want to change the current status"
        action={
          <Button
            variant="outlined"
            style={{
              backgroundColor: '#F5EFFD',
              color: '#421B9E',
            }}
          >
            Sure
          </Button>
        }
      />
    </>
  );
}
