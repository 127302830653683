import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  signup: icon('ic_signup'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  agents: icon('ic_agents'),
  equipments: icon('ic_equipments'),
  services: icon('ic_services'),
  category: icon('ic_category'),
  offers: icon('ic_offers'),
  discount: icon('ic_discount'),
  campaigns: icon('ic_compaigns'),
  gift: icon('ic_gift'),
  variants: icon('ic_variants'),
  carts: icon('ic_carts'),
  chats: icon('ic_chats'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('Business'),
            path: paths.dashboard.business.root,
            icon: ICONS.blog,
          },
          {
            title: t('Customers'),
            path: paths.dashboard.customers.root,
            icon: ICONS.user,
          },
          {
            title: t('Pre-SignUp'),
            path: paths.dashboard.signup.root,
            icon: ICONS.signup,
          },
          {
            title: t('Sponsors'),
            path: paths.dashboard.sponsors.root,
            icon: ICONS.user,
          },
          // {
          //   title: t('Partners'),
          //   path: paths.dashboard.partners.root,
          //   icon: ICONS.external  ,
          // },
          // {
          //   title: t('Payments'),
          //   path: paths.dashboard.payment.root,
          //   icon: ICONS.invoice,
          // },

          // {
          //   title: t('Team Members'),
          //   path: paths.dashboard.members.root,
          //   icon: ICONS.agents,
          // },
          // {
          //   title: t('Customers'),
          //   path: paths.dashboard.customer.root,
          //   icon: ICONS.user,
          // },
          {
            title: t('Plans'),
            icon: ICONS.analytics,
            path: paths.dashboard.subscription.root,
            children: [
              { title: t('Customer Subscriptions'), path: paths.dashboard.subscription.root },
              { title: t('Subscription Plans'), path: paths.dashboard.subscriptionPlans.root },
            ],
          },
          // {
          //   title: t('Booking'),
          //   path: paths.dashboard.booking.root,
          //   icon: ICONS.job,
          // },
          // {
          //   title: t('Service'),
          //   path: paths.dashboard.services.root,
          //   icon: ICONS.services,
          // },
          {
            title: t('Category'),
            path: paths.dashboard.category.root,
            icon: ICONS.category,
          },
          // {
          //   title: t('Offers'),
          //   path: '',
          //   icon: ICONS.offers,
          // },
          // {
          //   title: t('Discounts'),
          //   path: paths.dashboard.discounts.root,
          //   icon: ICONS.discount,
          // },
          {
            title: t('Compaigns'),
            path: paths.dashboard.campaigns,
            icon: ICONS.campaigns,
          },
          {
            title: t('Gift Cards'),
            path: paths.dashboard.admingiftcards,
            icon: ICONS.gift,
          },

          // {
          //   title: t('Calendar 2-way Sync'),
          //   path: paths.dashboard.calendar2way,
          //   icon: ICONS.calendar,
          // },
          // {
          //   title: t('Wallet'),
          //   path: paths.dashboard.wallet,
          //   icon: ICONS.invoice,
          // },
          // {
          //   title: t('Cart'),
          //   path: '',
          //   icon: ICONS.carts,
          // },
          // {
          //   title: t('Chats'),
          //   path: paths.dashboard.chat,
          //   icon: ICONS.chats,
          // },
          // {
          //   title: t('Equipments'),
          //   path: paths.dashboard.equipments.root,
          //   icon: ICONS.equipments,
          // },

          // {
          //   title: t('Campaigns'),
          //   path: paths.dashboard.campaigns,
          //   icon: ICONS.campaigns,
          // },
          {
            title: t('Types and Values'),
            path: paths.dashboard.typeValues.root,
            icon: ICONS.variants,
          },
          {
            title: t('FAQs'),
            path: paths.dashboard.faqs,
            icon: ICONS.menuItem,
          },
          {
            title: t('Status'),
            path: paths.dashboard.status,
            icon: ICONS.equipments,
          },
          {
            title: t('About Us'),
            icon: ICONS.tour,
            path: paths.dashboard.customerAboutUs,
            children: [
              { title: t('Customer'), path: paths.dashboard.customerAboutUs, },
              { title: t('Business'), path: paths.dashboard.businessAboutUs, },
            ],
          },
          {
            title: t('Privacy Policy'),
            icon: ICONS.lock,
            path: paths.dashboard.customerPrivacy,
            children: [
              { title: t('Customer'), path: paths.dashboard.customerPrivacy, },
              { title: t('Business'), path: paths.dashboard.businessPrivacy, },
            ],
          },
          // {
          //   title: t('Customer Privacy Policy'),
          //   path: paths.dashboard.customerPrivacy,
          //   icon: ICONS.lock,
          // },
          // {
          //   title: t('Business Privacy Policy'),
          //   path: paths.dashboard.businessPrivacy,
          //   icon: ICONS.lock,
          // },

          {
            title: t('Terms & Conditions'),
            icon: ICONS.kanban,
            path: paths.dashboard.customerTerms,
            children: [
              { title: t('Customer'), path: paths.dashboard.customerTerms, },
              { title: t('Business'), path: paths.dashboard.businessTerms, },
            ],
          },

          // {
          //   title: t('Terms & Conditions'),
          //   path: paths.dashboard.terms,
          //   icon: ICONS.kanban,
          // },
          {
            title: t('Contact Us'),
            path: paths.dashboard.contactUs,
            icon: ICONS.chat,
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      // {
      //   subheader: t('management'),
      //   items: [
      //     // USER
      //     // {
      //     //   title: t('user'),
      //     //   path: paths.dashboard.user.root,
      //     //   icon: ICONS.user,
      //     //   children: [
      //     //     { title: t('profile'), path: paths.dashboard.user.root },
      //     //     { title: t('cards'), path: paths.dashboard.user.cards },
      //     //     { title: t('list'), path: paths.dashboard.user.list },
      //     //     { title: t('create'), path: paths.dashboard.user.new },
      //     //     { title: t('edit'), path: paths.dashboard.user.demo.edit },
      //     //     { title: t('account'), path: paths.dashboard.user.account },
      //     //   ],
      //     // },

      //     // PRODUCT
      //     {
      //       title: t('product'),
      //       path: paths.dashboard.product.root,
      //       icon: ICONS.product,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.product.root },
      //         {
      //           title: t('details'),
      //           path: paths.dashboard.product.demo.details,
      //         },
      //         { title: t('create'), path: paths.dashboard.product.new },
      //         { title: t('edit'), path: paths.dashboard.product.demo.edit },
      //       ],
      //     },

      //     // INVOICE
      //     // {
      //     //   title: t('invoice'),
      //     //   path: paths.dashboard.invoice.root,
      //     //   icon: ICONS.invoice,
      //     //   children: [
      //     //     { title: t('list'), path: paths.dashboard.invoice.root },
      //     //     {
      //     //       title: t('details'),
      //     //       path: paths.dashboard.invoice.demo.details,
      //     //     },
      //     //     { title: t('create'), path: paths.dashboard.invoice.new },
      //     //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
      //     //   ],
      //     // },

      //     // BLOG
      //     {
      //       title: t('blog'),
      //       path: paths.dashboard.post.root,
      //       icon: ICONS.blog,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.post.root },
      //         { title: t('details'), path: paths.dashboard.post.demo.details },
      //         { title: t('create'), path: paths.dashboard.post.new },
      //         { title: t('edit'), path: paths.dashboard.post.demo.edit },
      //       ],
      //     },

      //     // JOB
      //     {
      //       title: t('job'),
      //       path: paths.dashboard.job.root,
      //       icon: ICONS.job,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.job.root },
      //         { title: t('details'), path: paths.dashboard.job.demo.details },
      //         { title: t('create'), path: paths.dashboard.job.new },
      //         { title: t('edit'), path: paths.dashboard.job.demo.edit },
      //       ],
      //     },

      //     // TOUR
      //     {
      //       title: t('tour'),
      //       path: paths.dashboard.tour.root,
      //       icon: ICONS.tour,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.tour.root },
      //         { title: t('details'), path: paths.dashboard.tour.demo.details },
      //         { title: t('create'), path: paths.dashboard.tour.new },
      //         { title: t('edit'), path: paths.dashboard.tour.demo.edit },
      //       ],
      //     },

      //     // FILE MANAGER
      //     {
      //       title: t('file_manager'),
      //       path: paths.dashboard.fileManager,
      //       icon: ICONS.folder,
      //     },

      //     // MAIL
      //     {
      //       title: t('mail'),
      //       path: paths.dashboard.mail,
      //       icon: ICONS.mail,
      //       info: <Label color="error">+32</Label>,
      //     },

      //     // CALENDAR
      //     {
      //       title: t('calendar'),
      //       path: paths.dashboard.calendar,
      //       icon: ICONS.calendar,
      //     },

      //     // KANBAN
      //     {
      //       title: t('kanban'),
      //       path: paths.dashboard.kanban,
      //       icon: ICONS.kanban,
      //     },
      //   ],
      // },

      // // DEMO MENU STATES
      // {
      //   subheader: t(t('other_cases')),
      //   items: [
      //     {
      //       // default roles : All roles can see this entry.
      //       // roles: ['user'] Only users can see this item.
      //       // roles: ['admin'] Only admin can see this item.
      //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
      //       // Reference from 'src/guards/RoleBasedGuard'.
      //       title: t('item_by_roles'),
      //       path: paths.dashboard.permission,
      //       icon: ICONS.lock,
      //       roles: ['admin', 'manager'],
      //       caption: t('only_admin_can_see_this_item'),
      //     },
      //     {
      //       title: t('menu_level'),
      //       path: '#/dashboard/menu_level',
      //       icon: ICONS.menuItem,
      //       children: [
      //         {
      //           title: t('menu_level_1a'),
      //           path: '#/dashboard/menu_level/menu_level_1a',
      //         },
      //         {
      //           title: t('menu_level_1b'),
      //           path: '#/dashboard/menu_level/menu_level_1b',
      //           children: [
      //             {
      //               title: t('menu_level_2a'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
      //             },
      //             {
      //               title: t('menu_level_2b'),
      //               path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
      //               children: [
      //                 {
      //                   title: t('menu_level_3a'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
      //                 },
      //                 {
      //                   title: t('menu_level_3b'),
      //                   path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
      //                 },
      //               ],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       title: t('item_disabled'),
      //       path: '#disabled',
      //       icon: ICONS.disabled,
      //       disabled: true,
      //     },
      //     {
      //       title: t('item_label'),
      //       path: '#label',
      //       icon: ICONS.label,
      //       info: (
      //         <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
      //           NEW
      //         </Label>
      //       ),
      //     },
      //     {
      //       title: t('item_caption'),
      //       path: '#caption',
      //       icon: ICONS.menuItem,
      //       caption:
      //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
      //     },
      //     {
      //       title: t('item_external_link'),
      //       path: 'https://www.google.com/',
      //       icon: ICONS.external,
      //     },
      //     {
      //       title: t('blank'),
      //       path: paths.dashboard.blank,
      //       icon: ICONS.blank,
      //     },
      //   ],
      // },
    ],
    [t]
  );

  return data;
}
