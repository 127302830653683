import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const bookingApi = createApi({
  reducerPath: 'booking',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Bookings'],
  endpoints: (builder) => ({
    getAllBooking: builder.query({
      query: ({ pageno, limit, search, branch }) =>
        `/bookings?branch=${branch}&pageno=${pageno + 1}&limit=${limit}&search=${search}`,
      transformResponse: (res) => res.body,
      providedTags: ['Bookings'],
    }),

    getbookingsById: builder.query({
      query: (id) => `/bookings/${id}`,
      transformResponse: (res) => res.body,
      providedTags: ['Bookings'],
    }),
  }),
});

export const { useGetAllBookingQuery, useGetbookingsByIdQuery } = bookingApi;
