import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import { paths } from 'src/routes/paths';
import { isOverflown } from '@mui/x-data-grid/utils/domUtils';
import { format } from 'date-fns';
import SelectOffer from './select-offer';
import { Box, Typography, useTheme } from '@mui/material';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useSettingsContext } from 'src/components/settings';
import { fShortenNumber } from 'src/utils/format-number';
// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  index,
  counter,
}) {
  const { user } = useMockedUser();
  const { title, price, picture, description, category, isActive } = row;

  const settings = useSettingsContext();

  const type = row?.offer?.type;
  const start = row?.offer?.start;
  const end = row?.offer?.end;
  const validTill = row?.offer?.validTill;

  const theme = useTheme();
  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const createOffer = useBoolean();

  const handleClick = () => {
    console.log(row);
  };
  return (
    <>
      <TableRow hover selected={selected} onClick={handleClick}>
        <TableCell>{counter}</TableCell>

        <TableCell>
          <Avatar
            variant="rounded"
            alt={'Picture'}
            src={row?.images?.length && row?.images[0]}
            sx={{ mr: 2, mb: 1 }}
          />

          <ListItemText
            primary={row?.title}
            secondary={''}
            primaryTypographyProps={{
              whiteSpace: 'nowrap',
              typography: 'body2',
            }}
            secondaryTypographyProps={{
              component: 'span',
              color: 'text.disabled',
              whiteSpace: 'nowrap',
            }}
          />
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label marginRight=".2rem" variant="soft" color={'primary'}>
            {row?.category?.name}
          </Label>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          <Label marginRight=".2rem" variant="soft" color={'primary'}>
            {row?.subcategory?.name}
          </Label>
        </TableCell>
        <TableCell
          sx={{ whiteSpace: 'nowrap' }}
          style={{
            maxWidth: '15rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Tooltip title={row.description} placement="top-start">
            {row?.description}
          </Tooltip>
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            '::first-letter': {
              textTransform: 'uppercase',
            },
          }}
        >
          {row?.gender}
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.time} Minutes</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row?.currency?.code + ' ' + fShortenNumber(row?.price)}
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '.5rem',
          }}
        >
          <Label variant="soft" color={'primary'}>
            {type ? `${type}` : 'N/A'}
          </Label>
          <Label variant="soft" color="default">
            {start && end ? `${start} - ${end}` : 'N/A'}
          </Label>
          <Label variant="soft" color="default">
            {validTill ? `Due Date ${format(new Date(validTill), 'dd-MM-yyyy')}` : 'N/A'}
          </Label>
        </TableCell>

        <TableCell>
          <Label variant="soft" color={(isActive && 'success') || 'warning'}>
            {isActive ? 'Active' : 'Inactive'}
          </Label>
        </TableCell>

        <TableCell>
          <ListItemText
            primary={`${format(new Date(), 'dd MMM yyyy')}`}
            secondary={`${format(new Date(), 'p')}`}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          {user?.user_type === 2 && (
            <>
              <Tooltip title="Set Discount" placement="top">
                <IconButton
                  color={createOffer.value ? 'inherit' : 'default'}
                  onClick={createOffer.onTrue}
                >
                  <Iconify
                    icon="solar:sale-bold-duotone"
                    width={25}
                    sx={{
                      color:
                        settings.themeMode === 'dark'
                          ? theme.palette.primary.light
                          : theme.palette.primary.dark,
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Quick Edit" placement="top" arrow>
                <IconButton
                  color={quickEdit.value ? 'inherit' : 'default'}
                  onClick={quickEdit.onTrue}
                >
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Tooltip>

              {/* <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>

          <IconButton color={popover.open ? 'inherit' : 'default'} href={paths.dashboard.customer.profile}>
            <Iconify icon="solar:eye-bold" width={24} />
          </IconButton> */}
            </>
          )}
        </TableCell>
      </TableRow>

      {quickEdit && (
        <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} />
      )}
      {createOffer.value && (
        <SelectOffer currentUser={row} open={createOffer.value} onClose={createOffer.onFalse} />
      )}
      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Delete
        </MenuItem>

        {/* <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Edit
        </MenuItem> */}
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure want to delete?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Delete
          </Button>
        }
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
