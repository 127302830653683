import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS, _userAbout, _userCards } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import { useGetAllServiceQuery } from 'src/store/Reducer/service';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { LoadingScreen } from 'src/components/loading-screen';
import UserNewEditForm from '../user-new-edit-form';
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';
import { useRef } from 'react';
import { useGetAllContactsQuery } from 'src/store/Reducer/settings';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Name' },
  { id: 'subject', label: 'Subject' },
  { id: 'message', label: 'Message' },
  { id: 'date', label: 'Date' },
  { id: '', label: '' },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ContactListView({ contacts }) {
  const { user } = useMockedUser();

  const table = useTable();

  const printRef = useRef(null);

  const [filters, setFilters] = useState(defaultFilters);

  const { data, status } = useGetAllContactsQuery({
    pageno: table.page,
    limit: table.rowsPerPage,
    search: filters.name,
  });

  const total_length = data?.total_pages;

  console.log('Contact Data = ', data);


  const settings = useSettingsContext();

  const quickCreate = useBoolean();

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!total_length && canReset) || !total_length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  let count = 0;

  let total = table.page * table.rowsPerPage;

  const handlePrint = () => {

    const printContents = printRef.current.innerHTML;

    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;


    const tds = document.querySelectorAll('td');
    tds.forEach(td => {
      td.style.color = "black"
    });

    window.print();

    document.body.innerHTML = originalContents;

    window.location.reload();
  };

  return (
    <>
      {status === 'pending' ? (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography>Loading contact messages, Please wait...</Typography>
        </Box>
      ) : (
        <Container maxWidth={settings.themeStretch ? false : '100%'}>
          <Card>
            <UserTableToolbar
              filters={filters}
              onPrint={handlePrint}
              onFilters={handleFilters}
              //
              roleOptions={_roles}
            />

            {canReset && (
              <UserTableFiltersResult
                filters={filters}
                onFilters={handleFilters}
                //
                onResetFilters={handleResetFilters}
                //
                results={total_length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}

            <TableContainer sx={{ position: 'relative', overflow: 'unset', zIndex: 100 }}>
              <Scrollbar ref={printRef}>
                {status === 'pending' ? (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                  </Box>
                ) : (
                  <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom headLabel={TABLE_HEAD} rowCount={total_length} />
                    <TableBody>
                      {data?.body?.map(
                        (row, index) => (
                          (count = count + 1),
                          (
                            <UserTableRow
                              key={row.id}
                              row={row}
                              selected={table.selected.includes(row.id)}
                              index={index + 1}
                              counter={count + total}
                            />
                          )
                        )
                      )}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, total_length)}
                      />
                      {total_length >= 1 ? '' : <TableNoData notFound={notFound} />}
                    </TableBody>
                  </Table>
                )}
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={total_length}
              page={table.page}
              rowsPerPage={table.rowsPerPage}
              onPageChange={table.onChangePage}
              onRowsPerPageChange={table.onChangeRowsPerPage}
            />
          </Card>
        </Container>
      )}
    </>
  );
}

// ----------------------------------------------------------------------
function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;

  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (user) => user && user.name && user.name.toLowerCase()?.indexOf(name.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }

  return inputData;
}
