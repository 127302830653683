import { useCallback, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Unstable_Grid2';

import Iconify from 'src/components/iconify';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {
  Avatar,
  Card,
  IconButton,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import DisplayBusinessSubcategories from '../display-business-subcategories';
import { Box, Container } from '@mui/system';
import moment from 'moment-timezone';
import { m } from 'framer-motion';
import DownloadButtons from 'src/sections/timezzi-Landing-Page/download_buttons';
import { varFade } from 'src/components/animate';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BUisnessPublicView() {
  const [branch, setbranch] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const { socialLinks, avialableSlots = [] } = branch ?? {};
  const [currentTab, setCurrentTab] = useState(0);
  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);
  const fetchBranch = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_HOST_API}/business/public/availbilties/${id}?timezone=${timeZone}`,
        {}
      );
      const { body } = res.data;
      setLoading(false);
      setbranch(body);
    } catch (error) {
      setLoading(false);
      console.error('There was an error making the request:', error);
    }
  };
  useEffect(() => {
    if (!branch) {
      fetchBranch();
    }
  }, [id]);
  return (
    <Container
      sx={{
        pt: 15,
        pb: 10,
        minHeight: 1,
      }}
    >
      <Grid container spacing={3}>
        <Grid
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2, 3, 3, 3),
          }}
        >
          <Stack sx={{ mb: 2 }} alignContent={'center'} direction="row" spacing={2}>
            <Avatar
              alt="image"
              src={branch?.image}
              sx={{ marginTop: '.5rem', width: 56, height: 56 }}
            />
            <Typography variant="h3" align="center" sx={{ mb: 2 }}>
              {' '}
              {branch?.name}
            </Typography>
          </Stack>
          <Typography color="info" variant="h5" sx={{ mb: 2 }}>
            Categories
          </Typography>
          {loading ? (
            <Skeleton variant="rounded" maxWidth={'100%'} height={'6rem'} />
          ) : (
            <DisplayBusinessSubcategories subcategories={branch?.categories} />
          )}{' '}
        </Grid>
        <Grid container spacing={2}>
          {/* About Section */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: (theme) => theme.spacing(2, 3, 3, 3),
            }}
          >
            <Typography color="info" variant="h5" sx={{ mb: 2 }}>
              About
            </Typography>
            {loading ? (
              <Stack spacing={2}>
                {/* Skeleton Loaders */}
                {[...Array(6)].map((_, index) => (
                  <Skeleton key={index} variant="rounded" width="100%" height="2rem" />
                ))}
              </Stack>
            ) : (
              <Stack spacing={2}>
                {/* Contact Number */}
                <Item sx={{ display: 'flex' }}>
                  <Iconify icon="solar:call-chat-rounded-bold-duotone" sx={{ mr: 2 }} />
                  <Typography>{branch?.contactNumber}</Typography>
                </Item>

                {/* Social Links */}
                {[
                  { icon: 'devicon:facebook', link: socialLinks?.facebook, label: 'facebook' },
                  {
                    icon: 'skill-icons:instagram',
                    link: socialLinks?.instagram,
                    label: 'instagram',
                  },
                  // { icon: 'logos:twitter', link: socialLinks?.twitter, label: 'twitter' },
                  { icon: 'devicon:linkedin', link: socialLinks?.linkedin, label: 'linkedin' },
                ].map((social, idx) => (
                  <Item sx={{ display: 'flex' }} key={idx}>
                    <Iconify icon={social.icon} color="#64b5f6" sx={{ flexShrink: 0, mr: 2 }} />
                    <a
                      href={`${social.link}` ?? '#'}
                      style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {social.link ? social.link : `Provide Social Links`}
                    </a>
                  </Item>
                ))}

                {/* Address */}
                <Item sx={{ display: 'flex' }}>
                  <Iconify icon="solar:map-point-wave-bold-duotone" sx={{ mr: 2 }} />
                  <Tooltip title={branch?.address?.line1}>
                    <Typography
                      sx={{
                        maxWidth: '15rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {branch?.address?.line1 || 'No address provided'}
                    </Typography>
                  </Tooltip>
                </Item>
              </Stack>
            )}
          </Grid>

          {/* Availabilities Section */}
          <Grid item xs={12} md={8}>
            <Typography color="info" variant="h5" sx={{ mb: 2 }}>
              Availabilities
            </Typography>
            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons={true}
              allowScrollButtonsMobile
            >
              {avialableSlots?.map((tab, index) => (
                <Tab
                  key={tab.date}
                  label={
                    <Card
                      sx={{
                        mb: 1,
                        p: 1,
                        border: tab.isOpen ? '2px solid gray' : '2px solid red',
                        transition: 'border 0.3s',
                        cursor: 'pointer',
                      }}
                    >
                      <Stack spacing={0} alignItems="center">
                        <Typography variant="body2">
                          {moment(tab.date).format('dddd')} {/* Show day */}
                        </Typography>
                        <Typography variant="body2">
                          {moment(tab.date).format('MMMM D, YYYY')} {/* Show date */}
                        </Typography>
                      </Stack>
                    </Card>
                  }
                  value={index}
                  onClick={() => setCurrentTab(index)}
                />
              ))}
            </Tabs>

            {/* Display available times when a date is selected */}
            {avialableSlots[currentTab]?.slots?.length ? (
              <Box
                sx={{
                  height: '300px', // Set a fixed height, adjust as needed
                  overflowY: 'auto', // Enable vertical scrolling
                }}
              >
                <Stack
                  sx={{
                    mt: 2,
                  }}
                  spacing={2}
                >
                  {avialableSlots[currentTab]?.slots?.map((item, idx) => (
                    <Card key={idx} sx={{ p: 1 }}>
                      {moment(item?.startTime).tz(timeZone).format('h:mm A')} -{' '}
                      {moment(item?.endTime).tz(timeZone).format('h:mm A')}
                    </Card>
                  ))}
                </Stack>
              </Box>
            ) : (
              <Typography variant="body2" sx={{ mt: 2 }}>
                No available times for this date.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <m.span variants={varFade().inUp}>
          <Box
            display={'flex'}
            rowGap={2}
            columnGap={2}
            marginTop="1rem"
            flexWrap={'wrap'}
            sx={{
              justifyContent: {
                lg: 'start',
                md: 'start',
                sm: 'center',
                xs: 'center',
              },
            }}
          >
              <DownloadButtons
              onClick={() => window.location.replace('/')}
              image={'/assets/icons/app/ic_dropbox.svg'}
              title="Home"
              description="Go to Home"
            />
            <DownloadButtons
              image={'/assets/icons/app/apple.svg'}
              title="App Store"
              description="Download on the"
            />
            <DownloadButtons
              image={'/assets/icons/app/playstore.svg'}
              title="Play Store"
              description="Get it on"
            />
          </Box>
        </m.span>
      </Box>
    </Container>
  );
}
